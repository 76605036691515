/* stylelint-disable */

@keyframes scaledown {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes scaleup {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/* stylelint-enable */

.loading-spinner {
  width: 3rem;
  height: 3rem;
  position: relative;
  display: inline-block;

  .circle1,
  .circle2 {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: $primary;
    animation: scaledown 2.8s infinite;
  }

  .circle2 {
    background: $indigo;
    transform: scale(0);
    animation: scaleup 2.8s infinite;
    animation-delay: 0.8s;
  }

  &.sm {
    width: 1rem;
    height: 1rem;
  }
}

.loading-dots {
  .loading-spinner:nth-of-type(2) {
    .circle1 {
      animation-delay: 0.4s;
    }

    .circle2 {
      animation-delay: 1.2s;
    }
  }

  .loading-spinner:nth-of-type(3) {
    .circle1 {
      animation-delay: 0.8s;
    }

    .circle2 {
      animation-delay: 1.6s;
    }
  }
}

////////// loading placeholders

.bar-placeholder,
.circle-placeholder {
  background: rgba($light, 0.5);
  width: 100%;
  height: 1rem;
  border-radius: $border-radius-sm;
  margin: 0.5rem 0;

  &.white {
    background: rgba($white, 0.4);
  }
}

.circle-placeholder {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  margin: 0.5rem;
}

.loading-placeholder {
  .bar-placeholder {
    height: 3rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.loading-modal {
  background: rgba($body-color, 0.5);
  position: fixed;
  top: 0;
  z-index: 10000;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
