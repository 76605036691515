.dropdown.action-menu {
  .dropdown-toggle {
    background: $gray-100;
    color: $primary;
    border: 0;
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 0.2rem 0.5rem;
    line-height: 0;

    &::after {
      display: none;
    }

    .dropdown-menu {
      margin-top: 0.2rem;
    }
  }

  &.small {
    .dropdown-toggle {
      font-size: 1.1rem;
      padding: 0.2rem;
    }
  }
}

// Style for patient flag action menu
.flag-action-menu {
  position: relative;

  .drop-menu {
    position: absolute;
    display: flex !important;
    column-gap: 3px;
    flex-direction: row;
    top: 33px;
  }

  .drop-menu.active {
    display: flex !important;
  }

  .drop-menu.inactive {
    display: none !important;
  }

  .drop-menu-list {
    display: inline-block;

    // width: 160px;
    // height: 144px;
    // min-width: 10rem;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 0 1px 0 rgb(255 255 255 / 38%) inset, 1px 1px 6px 0 rgb(64 83 82 / 9%);
    padding: 8px 0;
    align-self: flex-start;

    .unflag-patient {
      color: $green !important;
    }

    .flag-patient {
      color: $red !important;
    }

    .unflag-patient:active {
      background-color: white;
    }

    .flag-patient:active {
      background-color: white;
    }
  }

  .flag-dialog {
    width: 330px;
    box-shadow: 1px 1px 1px 0 rgb(255 255 255 / 38%) inset;
    filter: drop-shadow(0 0 6px rgb(64 83 82 / 9%));
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin-top: 20px;
    padding: 16px;

    .flag-reason-input {
      max-height: 300px;

      &.error {
        background-color: red;
      }
    }

    .btn:active {
      box-shadow: none !important;
    }

    .btn-primary:focus {
      box-shadow: none !important;
    }
  }

  .dropdown-toggle {
    background: $gray-100;
    color: $primary;
    border: 0;
    border-radius: 2rem;
    font-size: 1.5rem;
    padding: 0.2rem 0.5rem;
    line-height: 0;

    &::after {
      display: none;
    }

    .dropdown-menu {
      margin-top: 0.2rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .flag-action-menu {
    .drop-menu {
      flex-direction: column;
    }

    .flag-dialog {
      margin-top: 10px;
    }
  }
}
