$light-background: #e8f2f7;
$nav-bar-height: 60px;

.qapp-side {
  position: fixed;
  top: 0;
  width: 260px;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.qapp-side-nav {
  height: auto;
  min-height: 100%;
}

.qapp-side-nav-item {
  cursor: pointer;
  font-family: Overpass, sans-serif;
  font-weight: 700;
  width: 94%;
  margin: auto;
}

.divider {
  border-bottom: 0.8px solid #e3e3e3;
}

.qapp-side-child-element:hover {
  color: $dark !important;
}

.qapp-side-child {
  background-color: $light-background;
}

.qapp-side-user-name {
  display: flex;
  color: $primary;

  &::before {
    font-family: ehaclinics, sans-serif;
    display: flex;
    width: 1em;
    height: 1em;
    text-align: center;
    font-size: 1.3rem;
    content: "\e805";
  }
}

.qapp-side-user-name > .nav-link {
  font-size: 1.1rem !important;
}

.qapp-side-logo {
  display: block;
  width: 145px;
  height: 45px;
  margin-left: 0;
}

.qapp-side-logo-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .qapp-side {
    position: fixed;
    top: $nav-bar-height;
    background: $white;
    max-height: 50vh;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-width: none;
    transition: transform ease-in 0.5s;
    border-bottom: 1px solid $gray-600;
  }

  .qapp-side-logo {
    display: none;
  }

  .qapp-side-logo-container {
    display: none;
  }

  .qapp-side.collapsed {
    transform: translateY(-50vh) !important;
    transition: transform ease-out 0.3s;
  }

  .qapp-side-nav {
    width: 100%;
    overflow-y: auto;
  }

  .qapp-side-nav-item {
    width: 98%;
    margin: auto;
  }
}
