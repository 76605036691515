.patient-card {
  position: relative;
  padding: 0.5rem;
  font-size: 0.94rem;
  border-radius: $border-radius;
  background: white;
  box-shadow: $box-shadow;

  .label {
    color: $gray-700;
  }

  .card-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
