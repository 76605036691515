.nrd-pointer-class {
  cursor: pointer;
}

.nrd-title {
  font-family: Overpass, sans-serif;
  color: $dark;
  font-weight: 700;
  font-size: 20px;
}

.nrd-modal-card {
  width: 100%;
  display: flex;
  flex-direction: column;

  //   height: auto;
  //   border: 1px solid red;
  row-gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: #f8f9fd;
}

.nrd-modal-card:hover {
  cursor: pointer;
  background-color: #f2f4fa;
  border: 1px solid #41b5fd;
}

.nrd-item {
  background-color: #f2f4fa;
  border: 1px solid #41b5fd;
}

.nrd-modal-card > h5 {
  font-size: 16px;
  font-weight: 400;
}

.nrd-svg {
  position: relative;

  //   border: 1px solid red;
  min-height: 65px;
  max-height: 65px;
}

.nrd-number-percent {
  font-family: Overpass, sans-serif;
  position: absolute;
  top: 40%;
  left: 22%;
  font-size: 24px;
  font-weight: 900;
  color: $indigo;
}

.nrd-percent {
  font-size: 14px;
  font-weight: 600;
}

.nrd-body {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
  font-family: Overpass, sans-serif;
}

.nrd-row {
  row-gap: 25px;
}

@media screen and (max-width: 767px) {
  .nrd-svg-fluid {
    width: 40px;
    height: auto;
  }

  .nrd-modal-card > h5 {
    font-weight: 400;
    font-size: 14px;
  }

  .nrd-modal-card {
    width: 150px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100px;
    row-gap: 0;
    padding: 15px;
    border-radius: 10px;
    background-color: #e6e9f7;
  }

  .nrd-number-percent {
    top: 22%;
    left: 22%;
    font-size: 15px;
    font-weight: 900;
  }

  .nrd-percent {
    font-size: 12px;
  }
}

/**************   */
.nrd-sub-title {
  font-family: Overpass, sans-serif;
  color: $dark;
  font-weight: 700;
  font-size: 17px;
}
