.reports-area {
  position: relative;
  height: calc(100vh - ($topnav-height));

  .display {
    flex: 1 1;
    overflow-y: auto;
    border-left: 1px solid $light;
  }

  @media screen and (max-width: 768px) {
    .display {
      margin-top: 4rem;
    }

    .side-nav {
      background: $white;

      .side-nav-toggle {
        color: $primary;
      }

      .side-nav-content {
        color: $body-color;
      }

      &.collapsed {
        .nav-link.active {
          color: $body-color;
          top: 1.3rem;
        }
      }
    }
  }
}
