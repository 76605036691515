@keyframes slide-in {
  from {
    left: -10rem;
  }

  to {
    left: 0;
  }
}

.appointment-slider-container {
  position: fixed;
  z-index: 100;
  left: 0;
  top: $topnav-height;
  bottom: 0;
  width: 20vw;
  min-width: 18rem;
  max-width: 30rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-top-right-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  box-shadow: $drop-shadow;
  animation: slide-in 0.2s ease-out;

  @media screen and (max-width: 576px) {
    width: 100vw;
    max-width: none;
  }

  .slider-header {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid $light;
  }

  .slider-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .slider-content-scrollable {
    padding: 1rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .slider-footer {
    margin-top: auto;
    border-top: 1px solid $light;
  }

  .selected-schedule,
  .selected-service,
  .selected-care-provider {
    display: flex;
    align-items: baseline;
    background: rgba($primary, 0.2);
    border-radius: $border-radius-sm;
    margin-bottom: 1px;
    padding: 0.5rem 0.8rem 0.3rem;
  }
}

.patient-details-box {
  background: $light;
  border-radius: $border-radius;
  padding: 1rem;
  font-size: 0.94rem;

  &.grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 0.5rem;
  }

  .label {
    font-size: 0.8rem;
  }
}

.appointment-search {
  background: rgba($body-color, 0.1);
  border: none;
  color: $white;
  border-radius: 2em;

  input {
    color: $white;
    padding: 0.7rem 0.6rem 0.6rem;
  }

  input::placeholder {
    color: rgba($white, 0.5);
  }

  &:focus-within {
    background: rgba($body-color, 0.2);
    border: none;
  }

  &:hover {
    background: rgba($body-color, 0.2);
  }
}
