.qapp-header {
  display: none;
}

@media screen and (max-width: 768px) {
  .qapp-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    height: $nav-bar-height;
    z-index: 20;
    padding: 10px;
    background-color: white;
  }
}
