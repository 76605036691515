/* stylelint-disable selector-class-pattern */

.chart-review-bg {
  width: 100%;
  min-height: 100vh;
  background: $white;
  padding: 8px 8px 0;
}

.chart-review-main {
  width: 100%;
  min-height: 100vh;
  background-color: #f7fbfc;
  display: flex;
  padding-top: 15px;
  padding-bottom: 40px;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  filter: drop-shadow(0 2px 3px rgb(0 0 0 / 16%));
}

.chart-review-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  margin-right: 13px;
}

.customElement {
  line-height: 120%;
  list-style: none;
}

.review-details {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1.5fr;
}

.prescription-audit-details {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
}

.review-details-badge {
  font-size: 13px;
  width: 80%;
}

@media screen and (max-width: 576px) {
  .review-details {
    display: flex;
    flex-direction: column;
  }

  .review-details-badge {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .chart-review-header {
    column-gap: 3px;
  }
}

@media screen and (max-width: 1200px) {
  .review-details {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 992px) {
  .review-details-badge {
    font-size: 11px;
  }
}

/**** Chart Review List Styles *****/

.chart-review-list-bg {
  width: 100%;
  min-height: 100vh;
  background: $white;
  padding: 8px 0 0 8px;

  // border: 1px solid red
}

.crl-form {
  width: 100%;
  background: #8c96b5;
  border-radius: 6px;
  position: relative;

  &.crl-form-date {
    display: flex;
    align-items: center;
    color: $white;

    // border: 1px solid red;

    padding: 0 10px 0 0;
  }

  .crl-label {
    position: absolute;
    left: 10px;
  }

  .crl-child {
    // margin-left: 5px;
    // padding-left: 40px;
    width: 100%;
  }
}

.chart-review-list-main {
  width: 100%;
  min-height: 100vh;
  background: $white url("/img/bg.jpg");
  display: flex;

  // padding-top: 15px;
  overflow: hidden;
  padding-bottom: 40px;
  flex-direction: column;
  border-top-left-radius: 15px;

  // filter: drop-shadow(0 2px 3px rgb(0 0 0 / 16%));

  //  The list children styles go here

  .chart-review-list-header {
    display: flex;
    flex-direction: column;
    background: $purple;
    align-items: center;
    width: 100%;

    .chart-review-filter {
      // border: 1px solid red
      box-shadow: $box-shadow;
    }
  }

  .chart-review-header-pending {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // border: 1px solid red;
    padding: 15px 20px 10px;

    h5 {
      // border: 1px solid red;
      color: $white;
    }

    .chart-date-filter {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}

/*** react date classes ****/

.crl-year-filter {
  background: transparent;
  width: 180px;

  .react-datepicker-wrapper {
    border: none;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container .crl-custom-date-input {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
  }

  .react-datepicker__input-container input:focus {
    background-color: transparent;
  }

  .react-datepicker__input-container input:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container input {
    box-shadow: none;
    height: 40px;
    border-radius: 0;

    &::before {
      font-family: ehaclinics, sans-serif;
      display: flex;
      width: 1em;
      height: 1em;
      text-align: center;
      font-size: 1.3rem;
      content: "\e805";
    }
  }

  .react-datepicker-popper .react-datepicker {
    .react-datepicker__header {
      height: 3rem !important;
    }
  }
}

.crl-month-filter {
  background: transparent;
  width: 220px;

  .react-datepicker-wrapper {
    border: none;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container .crl-custom-date-input {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px;
  }

  .react-datepicker__input-container input:focus {
    background-color: transparent;
  }

  .react-datepicker__input-container input:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container input {
    box-shadow: none;
    height: 40px;
    border-radius: 0;

    &::before {
      font-family: ehaclinics, sans-serif;
      display: flex;
      width: 1em;
      height: 1em;
      text-align: center;
      font-size: 1.3rem;
      content: "\e805";
    }
  }

  .react-datepicker-popper .react-datepicker {
    .react-datepicker__header {
      display: none !important;
    }
  }

  .react-datepicker {
    .react-datepicker__navigation {
      display: none !important;
    }

    .react-datepicker__month-text--keyboard-selected {
      background: $primary;
    }
  }
}

.chart-review-table {
  .chart-review-pagination-bar {
    padding-left: 13px;
    padding-right: 13px;
    box-shadow: none;
    border: none;
  }

  .chart-review-view-toogle {
    padding: 1px 10px;
    background-color: $white;
    display: flex;
    align-items: center;
    column-gap: 5px;
    border-radius: 20px;
    box-shadow: $box-shadow;
    margin-left: 15px;
    margin-right: 5px;
  }

  .view-icon {
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .eha-table {
    width: 100%;
    font-family: Overpass, sans-serif;

    thead,
    tbody {
      width: 100%;
    }

    tbody {
      background: transparent !important;
      margin-top: 16px !important;
    }

    th,
    td {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
      padding: 0 !important;

      // border: 1px solid red;
    }

    th > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td > div {
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }

    td > a {
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }

    // status and branch are span elements so ellipsis will not apply to them
    // regardless of the select statement below. However use this idea to block
    // future element from having the ellipsis style

    td:not(.chart-branch):not(.chart-status) > div {
      text-overflow: ellipsis;
    }

    .chart-review-table-header-row {
      min-height: 40px;
      display: grid;
      grid-template-columns: 3fr 2fr 3fr 3fr 3fr 2fr 2.7fr !important;
      width: 98%;
      margin: auto;

      th {
        padding: 0 4px !important;
      }

      .status-header {
        display: flex;
        justify-content: flex-end;
      }
    }

    .chart-review-table-body {
      width: 98%;
      margin: auto;
      margin-top: 16px !important;
    }

    .chart-review-image {
      width: 35px;
      height: 35px;
      min-width: 35px !important;
      min-height: 35px !important;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid rgb(188 187 187);
    }

    .chart-review-initials {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .chart-review-table-body-row {
      display: grid;
      grid-template-columns: 3fr 2fr 3fr 3fr 3fr 2fr 2.7fr !important;
      padding: 8px 5px;
      margin-top: 3px;

      td {
        padding: 0 4px !important;
      }

      .chart-review-body-cell {
        width: 100%;
      }

      .chart-review-body-cell:last-child {
        display: flex;
        justify-content: flex-end;
      }

      .chart-reviewer-class {
        display: flex;
        column-gap: 10px;
        align-items: center;
      }

      // Use ellipse for the text of the first child in d Table for ChartReviewList
      .chart-reviewer-class div:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }

      .chart-review-branch {
        padding: 3px 6px 0;
        font-weight: 600;
        font-size: 12px;
        border-radius: 20px;
        height: auto !important;
        background-color: $gray-400;
      }

      .status-badge-red,
      .status-badge-green {
        padding: 3px 6px 0;
        color: white;
        font-weight: 600;
        font-size: 10px;
        border-radius: 20px;
        height: auto !important;
      }

      .status-badge-red {
        background-color: $danger;
      }

      .status-badge-green {
        background-color: $success;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .chart-review-header-pending {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  h5,
  .chart-date-filter {
    align-self: flex-start;
  }

  .chart-date-filter {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 10px;
  }

  .crl-year-filter,
  .crl-month-filter {
    align-self: flex-start;
  }
}

@media screen and (max-width: 992px) {
  .chart-review-list-bg {
    padding: 5px 0 0;

    // border: 1px solid red;
  }

  .chart-review-table {
    .eha-table {
      .chart-review-table-header-row {
        display: grid;
        grid-template-columns: 1fr 1.5fr 1.7fr 1fr 1.2fr !important;

        th {
          // border: 1px solid red;
        }

        .chart-encounter,
        .chart-provider,
        .chart-nurse,
        .chart-status {
          display: none;
        }
      }

      .chart-review-table-body-row {
        display: flex;
        flex-wrap: wrap;

        // align-items: baseline;
        padding: 10px 15px;
        position: relative;

        td.chart-reviewer {
          font-weight: 600;
          width: 100%;
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }

        td.chart-encounter,
        td.chart-encounter-id,
        td.chart-provider,
        td.chart-nurse,
        td.chart-branch,
        td.chart-date,
        td.chart-encounter-date {
          width: 50%;
          min-width: 300px;

          // font-size: 13px;
          // display: flex;
          vertical-align: middle;

          // align-items: center;
          // border: 1px solid red;

          &::before {
            content: attr(title);
            display: inline-block;
            width: 7rem;
            font-family: Asap, sans-serif;
            font-weight: 400;
            font-style: italic;
            font-size: 12.5px;
            margin-top: -3px;
          }
        }

        td.chart-status {
          position: absolute;
          right: 1rem;
          bottom: 0.6rem;
        }
      }
    }
  }
}

// Styling fix for lognote
.chart-review-lognote {
  padding: 40px 25px 20px;
}
