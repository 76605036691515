// For the channels section
.no-messages {
  height: calc(100% - 72px); // minus chatboxheader height
  max-height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;

  // border: 1px solid red;
  position: relative;
  padding: 10px 20px;
  word-break: break-all;

  .no-messages-created-at {
    color: #6e7f80;
    font-size: 12px;
  }

  .no-messages-desc {
    color: #6e7f80;
    font-size: 14px;
  }

  .no-messages-body {
    // position: absolute;
    // left: 35%;
    // top: 32%;
    flex: 1;

    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #405352;
    row-gap: 10px;
    margin-top: -100px;

    .icon-circle {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background: #d9e9ee;
      display: flex;
      justify-content: center;
      align-items: center;

      .eha-file {
        color: #405352;
        font-size: 40px !important;
      }
    }

    h5 {
      font-size: 20px;
    }

    p {
      width: 400px;
      text-align: center;
    }
  }
}

.no-mr-messages {
  height: calc(100% - 72px); // minus chatboxheader height
  max-height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;
  justify-content: flex-end;
}
