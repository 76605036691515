.flow-status {
  border-radius: $border-radius-sm;
  border: 1px solid $light;
  background: $gray-100;
  font-size: 0.8rem;
  display: flex;
  color: $body-light;
  border-color: $gray-500;

  .status {
    text-transform: uppercase;
    display: inline-block;
    padding: 0.1rem 0.2rem 0;
  }

  .time {
    font-size: 0.8rem;
    display: inline-block;
    border-left: 1px solid;
    border-color: inherit;
    padding: 0 0.2rem;
  }

  /* stylelint-disable */

  &.success {
    border-color: lighten($success, 20);

    .status {
      color: darken($success, 20);
    }
  }

  &.danger {
    border-color: lighten($danger, 20);

    .status {
      color: $danger;
    }
  }

  /* stylelint-enable */
}
