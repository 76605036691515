.mobile-sidebar {
  position: fixed;
  height: 100%;
  max-height: 100%;
  left: 0;
  z-index: 10;
  background-color: #5b74c6;
  box-shadow: 2px 0 3px 0 rgb(147 167 166 / 28%) inset;
  display: flex;
  flex-direction: column;

  .mobile-sidebar-top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }

  &.collapsed {
    padding: 6px;
    width: 70px;

    // transition: all ease-in 0.1s;

    .new-message-mobile {
      margin-top: 10px;
      width: 100%;
    }

    .mobile-sidebar-top-section {
      padding: 20px 0;
      width: 100%;
      row-gap: 15px;
    }

    .btn-primary {
      padding: 0;
      height: 30px;
    }

    .btn-primary.dropdown-toggle {
      color: white !important;
      padding: 0;
      height: 30px;
    }
  }

  &.expanded {
    width: 245px;
    transition: all ease-in 0.2s;

    .mobile-sidebar-top-section {
      padding: 20px 10px;
      width: 100%;
    }

    .new-message-mobile {
      margin-top: 20px;
    }
  }

  .toggle-sidebar {
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // background-color: rgb(217 233 238 / 20%);
    background-color: $primary;
    right: -7px;
    top: 130px;
    color: white;
    cursor: pointer;
  }

  .mc-accordion-section {
    // height: calc(100% - 80px);
    flex: 1;
    width: 100%;
    margin-top: 40px;

    &.expanded {
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px;

      &.styled-scrollbar {
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: #5b74c6;
        }

        &::-webkit-scrollbar-thumb {
          background: #9f9f9f;
          border-radius: 4px;
        }
      }
    }

    &.collapsed {
      padding: 10px 2px;
    }
  }

  .ms-patient-header {
    &.expanded {
      display: flex;
      margin: auto;
      color: $white;
      font-size: 13px !important;
      padding: 3px 14px;
      margin-top: 20px;
    }

    &.collapsed {
      display: none;
    }
  }

  .sidebar-navitem-parent {
    padding: 10px 2px;
    border-top: 0.7px solid;
    border-color: rgb(217 233 238 / 20%);

    .ms-messages.expanded {
      // transition: all ease-in 0.2s;

      // padding: 5px 23px;
      padding: 0;
      margin: 0;
      margin-top: 10px;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      color: $white;

      li {
        padding: 0 13px;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 14px;
        column-gap: 10px;
        border-radius: 10px;
        cursor: pointer;
        color: white !important;

        &.ms-item-selected {
          background: rgb(0 0 0 / 16%);
        }

        .ms-image-and-name {
          display: flex;
          align-items: center;
          column-gap: 10px;
          flex-grow: 1;

          .name-initials {
            width: 25px;
            height: 25px;
            font-size: 12px;
            border-radius: 100%;
            border: 1px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            color: white;
          }

          //    For the image parent element
          .ms-profile-img {
            position: relative;

            .ms-channel-hash {
              margin-left: 10px;
              font-size: 16px;
              font-weight: 600;
            }

            img {
              border-radius: 100%;
              height: 25px;
              width: 25px;
              max-width: 25px;
              max-height: 25px;
              object-fit: cover;
            }

            .status-dot {
              position: absolute;
              right: 0;
              top: 20px;
              width: 7px;
              height: 7px;
              border-radius: 100%;
              z-index: 10;

              &.active {
                background-color: $green;
              }

              &.inactive {
                background-color: $purple;
              }
            }
          }
        }

        .message-count-badge {
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          color: $dark;
          font-size: 12px;
          border-radius: 100%;
          padding-top: 3px;
        }

        &:hover {
          background: rgb(0 0 0 / 4%);
        }
      }
    }

    .ms-messages.collapsed {
      display: none;
    }
  }

  .mobile-sidebar-navitem {
    display: flex;
    align-items: center;
    color: white;
    height: 40px;
    font-size: 1.1rem;
    cursor: pointer;

    // border: 1px solid blue;

    &:hover {
      background-color: rgb(255 255 255 / 16%);
    }

    &.selected-sidebar-navitem {
      background-color: rgb(255 255 255 / 16%);
    }

    &.expanded {
      justify-content: flex-start;
      column-gap: 6px;
      padding: 0 10px 0 6px;

      // border: 1px solid red;
      border-radius: 8px;
    }

    &.collapsed {
      justify-content: center;
      border-radius: 5px;

      .logo-parent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }

      .sidebar-logo {
        font-size: 20px !important;
        margin-top: 5px;
      }

      .ms-messages {
        display: none;
      }
    }
  }

  // Plus icons dropdown classes

  .ms-plus-icon {
    padding: 1px;

    // border: 1px solid red;
    border-radius: 100%;
    margin-top: 14px;
    background-color: rgb(255 255 255 / 22%);
  }

  .plus-icon-dropdown {
    position: relative;

    .ms-plus-icon {
      margin-top: 10px;
    }

    .plus-icon-dropdown-menu {
      position: absolute;
      z-index: 10;
      padding: 15px 10px;
      background-color: white;
      box-shadow: $box-shadow;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      width: 160px;
      transform: translateX(-80%);
    }

    .plus-icon-menu-item {
      color: $dark;
      font-size: 14px;
      border-radius: 8px;
      padding: 5px 10px;

      &:hover {
        background-color: $gray-400;
      }
    }
  }
}

// New message action menu styles
.dropdown.new-message-mobile-action-menu {
  width: 100% !important;
  background: transparent !important;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-primary.dropdown-toggle {
    color: white !important;
  }

  .dropdown-toggle {
    width: 100% !important;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 9px;
  }

  .dropdown-menu.show {
    width: 160px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4px;
    padding: 6px !important;
  }

  .dropdown-item {
    padding: 5px;

    &:active {
      background-color: $gray-400 !important;
      color: $dark !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .mobile-sidebar {
    display: none;
  }
}
