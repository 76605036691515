/* stylelint-disable */

.ehr-form .ehr-form,
.ehr-form .ehr-form--theme,
.ehr-form .ehr-form--theme.default {
  --b-ehr-form-background: none;
  --b-ehr-form-font-size: auto;
  --b-input-value-color: #314342;
  --b-input-value-disabled-color: #314342;
  --b-input-value-focused-color: #314342;
  --b-ng-select-value-badge-color: #314342;
  --b-invalid-text-color: #d3676b;
  --b-button-selected-border-color: #5b74c6;
  --b-button-selected-color: #5b74c6;
  --b-input-placeholder-font-family: overpass, sans-serif;
  --b-labeled-input-font-family: overpass, sans-serif;
  --b-invalid-text-font-family: overpass, sans-serif;
  --b-labeled-input-hover-border-color: #dbe9ef;
  --b-ng-select-item-hovered-background-color: #dbe9ef;
  --b-card-multiplicity-button-background-color: #dbe9ef;

  padding: 0;
}

.ehr-form {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  hr.transparent-border {
    background: none;
  }

  .presentation-heading {
    margin-top: 1rem;
  }

  .labeled-input .labeled-input--label,
  .labeled-input.type-multimedia .labeled-input--label {
    margin: 0.5rem 0 0;
    padding: 0;
  }

  .ehr-rich-field-label {
    letter-spacing: 0;
    color: $gray-900;
  }

  .labeled-input .ehr-rich-field-label {
    display: block;
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 0.94rem;
    color: $gray-900;
  }

  .labeled-input.form-group.presentation-mode {
    box-shadow: none;
    background: rgba($light, 0.2);
    margin: 1px;

    label.col-form-label {
      font-size: 0.94rem !important;
    }
  }

  .presentation-value {
    padding-top: 0.4rem;
  }

  .labeled-input.form-group.disabled {
    background: none;
    padding: 0 0.6rem;
    margin: 0;
    margin-bottom: $spacer;

    .b-form-input {
      padding-left: 0;
    }
  }

  .labeled-input.form-group:not(.presentation-mode):not(.disabled) {
    background: rgba($light, 0.2);
    box-shadow: $box-shadow-inset;
    border: 1px solid rgba($light, 0.3);
    border-radius: $border-radius-sm;
    padding: 0 0.6rem;
    position: relative;
    margin: 0;
    margin-bottom: $spacer;
    border-width: 1px !important;

    .b-form-btn {
      padding: 0.25rem 0.6rem 0.05rem;

      .b-form-btn--check-icon {
        left: 0.3rem;
      }
    }

    &:focus-within {
      background: rgba($light, 0.4);
      border: 1px solid rgba($light, 0.6);
    }

    &:hover {
      background: rgba($light, 0.4);
    }

    &.invalid {
      background: rgba($danger, 0.1);
      border: 1px solid rgba($danger, 0.15);

      &:focus-within {
        background: rgba($danger, 0.15);
        border: 1px solid rgba($danger, 0.2);
      }

      &:hover {
        background: rgba($danger, 0.15);
      }
    }
  }

  .ehr-select-wrapper {
    padding-top: 0.6rem;
  }

  .input-group,
  .quantity-input {
    padding-top: 0.6rem;

    .ehr-select-wrapper {
      padding-top: 0;
    }
  }

  .labeled-input .b-form-input {
    padding: 0.4rem 0 0.6rem 1rem;
  }

  .labeled-input.type-boolean {
    align-items: flex-start;
  }

  .labeled-input.type-boolean .b-form-input {
    padding-left: 0;
    padding-right: 0.6rem;
  }

  .labeled-input.type-date-time .b-form-input {
    padding-top: 1.2rem;
  }

  .labeled-input.type-date .b-form-input {
    padding-top: 0.8rem;
  }

  .labeled-input .form-control {
    font-weight: 600;
  }

  .labeled-input.no-value:not(.disabled):not(:hover) {
    border-bottom-color: rgba($light, 0.3);
  }

  .form-renderer-select.ng-select
    .ng-select-container
    .ng-value-container
    .ng-value,
  .ng-dropdown-panel.ehr-form
    .ng-select-container
    .ng-value-container
    .ng-value {
    font-weight: 600;
  }

  .form-renderer-select.ng-select,
  .ng-dropdown-panel.ehr-form {
    font-family: Overpass, sans-serif;
  }

  .form-renderer-select.ng-select-multiple {
    margin-top: -0.4rem;
  }

  .form-renderer-select.ng-select .ng-arrow-wrapper .ng-arrow:before,
  .ng-dropdown-panel.ehr-form .ng-arrow-wrapper .ng-arrow:before,
  .ng-select .ng-clear-wrapper .ng-clear {
    line-height: 0;
  }

  .form-control--error-message {
    letter-spacing: 0;
  }

  .b-form-btn.selected i.b-icon {
    color: var(--b-button-selected-color);
  }

  [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }

  .tooltip-indicator {
    margin-top: -4px;
    margin-bottom: 0;
  }

  .file-upload {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .file-upload .file-upload-area {
    margin-top: 0;
  }

  .validation-error--wrapper {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
  }
}

.ngb-datepicker.dropdown-menu,
.dropdown-menu.ehr-form {
  box-shadow: $drop-shadow;
}

.ehr-form hr {
  background: none;
  border-top: 1px solid $info;
}

// Items in dropdown menu
.ehr-form
  .form-renderer-select.ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option,
.ehr-form
  .form-renderer-select.ng-select.form-renderer-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option,
.ehr-form
  .ng-dropdown-panel.ehr-form
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option,
.ehr-form
  .ng-dropdown-panel.ehr-form.form-renderer-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  padding: 0.7em 2em 0.7em 0.5em;
}

.ehr-form
  mrd-quantity-input
  .quantity-unit
  .form-renderer-select.ng-select
  .ng-select-container {
  height: auto;
}

.ehr-form.ngb-dp-body {
  z-index: 1060;
}

/* stylelint-enable */
