.eha-topnav {
  height: $topnav-height;
  box-shadow: $drop-shadow;

  .navbar-brand {
    line-height: 0;
    height: $spacer * 3;
  }

  .nav-link {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 0.5rem;
  }

  .user-name .nav-link {
    text-transform: none;
    letter-spacing: 0;
    color: $white;
    font-size: 1rem;

    @include media-breakpoint-down(xl) {
      font-size: 0.9rem;
    }

    &::before {
      font-family: ehaclinics, sans-serif;
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 0;
      margin-right: 0.5em;
      text-align: center;
      content: "\e805";
    }
  }

  .qapp-user-name .nav-link {
    text-transform: none;
    letter-spacing: 0;
    color: $black;
    font-size: 1rem;

    @include media-breakpoint-down(xl) {
      font-size: 0.9rem;
    }

    &::before {
      font-family: ehaclinics, sans-serif;
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 0;
      margin-right: 0.5em;
      text-align: center;
      content: "\e805";
    }
  }

  .dropdown-menu {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    .dropdown:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

  .notifications {
    line-height: 1em;
    background: rgba($secondary, 0.2);
    height: $topnav-height;
    max-height: $topnav-height;
    display: flex;
    align-items: center;
    min-width: 65px;

    i:not(:only-child) {
      color: $white;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      background: none;
      right: 5rem;
    }
  }

  // chat-app-link
  .chat-app-link {
    line-height: 1em;
    background: rgba($secondary, 0.2);
    height: $topnav-height;
    max-height: $topnav-height;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;

    // border: 1px solid red;

    .eha-chat {
      font-size: 26px;
      color: white;
      margin-top: -10px;
    }

    .chat-notify {
      position: absolute;
      width: 12px;
      height: 12px;
      background: #565e5e;

      // background: blue;
      // border: 1px solid white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -4px;
      right: 3px;
      z-index: 10;

      .eha-circle {
        font-size: 9px;
        color: red;
        margin-top: 2px;
      }
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      background: none;
      right: 10rem;
    }
  }

  &.navbar {
    padding: 0.3rem 0;
  }

  .navbar,
  .navbar-collapse.collapse,
  .navbar-collapse.collapsing {
    padding: $spacer * 0.4 $spacer 0;

    @include media-breakpoint-down(lg) {
      background: $gray-900;
      padding: $spacer $spacer;
    }
  }
}

.eha-topnav ~ .main {
  margin-top: $topnav-height;
  position: relative;
}

.bookmarks {
  position: fixed;
  bottom: 0;
  background: $purple;
  width: 100vw;
  display: flex;
  padding: 0 0.5rem;
}

.bookmark {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  flex: 1 1 auto;
  background: $white;
  color: $body-color;
  border-radius: $border-radius $border-radius 0 0;
  padding: 0.2rem 0;
  margin: 0.75rem 0.2rem 0;
  width: 10%;
  max-width: 16rem;

  .bookmark-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// Link Navigation Styles

.ln-dropdown-parent {
  .btn {
    padding: 0 !important;
  }

  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn-check:focus + .btn-primary,
  .btn-primary:focus {
    box-shadow: none !important;
  }
}

.ln-dropdown {
  background-color: transparent !important;
  box-shadow: none;
  display: flex;
  align-items: center;
}

.ln-dropdown.dropdown-toggle {
  border: none;
}

.ln-dropdown-menu {
  width: 220px;
  margin-top: 10px !important;
}

.ln-dropdown.dropdown-toggle::after {
  border: none;
  font-family: ehaclinics, sans-serif;
  display: flex;
  width: 0.8em;
  height: 0.8em;
  margin-top: -5px;
  text-align: center;
  font-size: 1rem;
  color: $primary;
  content: "\e81a";
  transform: rotate(0deg) !important;
}

.ln-dropdown.dropdown-toggle[aria-expanded="true"] {
  box-shadow: none !important;
}

.ln-dropdown.dropdown-toggle[aria-expanded="true"]::after {
  border: none;
  font-family: ehaclinics, sans-serif;
  display: flex;
  width: 0.8em;
  height: 0.8em;
  margin-top: -5px;
  text-align: center;
  font-size: 1rem;
  color: $primary;
  content: "\e81a";
  transform: rotate(0deg) !important;
}

.ln-dropdown.dropdown-toggle[aria-expanded="false"]::after {
  border: none;
  font-family: ehaclinics, sans-serif;
  display: flex;
  width: 0.8em;
  height: 0.8em;
  margin-top: 10px;
  text-align: center;
  font-size: 1rem;
  color: $primary;
  content: "\e81a";
  transform: rotate(180deg) !important;
}

.settings-logo {
  // background-color: white;
}

.settings-logo-main {
  display: flex;
  align-items: center;
  position: relative;
}

.config-image-text {
  line-height: 13px;
  margin-top: 5px;
  color: whitesmoke;
}

.settings-text-svg {
  // border: 1px solid red;

  // padding-bottom:-10px;
  margin-top: 8px;
}

// custom navbar dropdown styles
.cust-nav-dropdown {
  position: relative;
  cursor: pointer;
  margin-left: 4px;

  // height: $topnav-height;
  display: flex;
  align-items: center;

  .cust-nav-dropdown-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .cust-seperator {
    border: 1px solid rgb(217 233 238 / 30%);
    height: 28px;
    margin: 0 8px 0 10px;
  }

  .ln-eha-logo {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .cust-seperator-qapp {
    border: 1px solid rgb(109 110 110 / 30%);
    height: 28px;
    margin: 0 8px 0 10px;
  }

  .cust-nav-dropdown-arrow {
    color: $primary;
  }

  .cust-nav-dropdown-menu {
    position: absolute;
    top: 52px;
    width: 210px;
    background-color: white;
    border-radius: 4px;
    padding: 4px 0;
    box-shadow: 0 0 1px 0 rgb(255 255 255 / 38%) inset, 0 2px 8px 0 rgb(64 83 82 / 42%);
  }

  .cust-nav-dropdown-menu.active {
    display: block;
  }

  .cust-nav-dropdown-menu.inactive {
    display: none;
  }

  .cust-nav-dropdown-item {
    padding: 15px 0 8px;
    color: $dark;
    padding-left: 14px;

    &:hover {
      background-color: #f4f8f6;
    }

    .config-text {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 13px !important;
      font-weight: 300 !important;
    }

    .cust-nav-dropdown-title {
      font-family: Asap, sans-serif;
      font-style: italic;
      font-weight: 400;
      font-size: 0.94rem;
    }
  }
}

// New layout styles

.loading-container {
  background: $white url("/img/bg.jpg");
  height: 100vh;
  width: 100vw;
}

@media screen and (min-width: 990px) and (max-width: 1280px) {
  .ln-eha-logo {
    display: none !important;
    height: 100%;
  }
}
