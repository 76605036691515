.letter {
  padding: 2em;

  .referral-logo {
    width: 200px;
    position: relative;
    left: -53px;
    margin-bottom: 0.6em;

    img {
      width: 100%;
    }
  }

  .letter-title {
    font-weight: 600;
    font-size: 1.7rem;
  }

  .label {
    font-size: 0.9em;
    color: $gray-800;
  }

  .value-row {
    margin: 0.25em 0;
    display: flex;
    align-items: baseline;

    .label {
      width: 40%;
      padding-right: 0.5em;
    }
  }

  .letter-field {
    height: 4rem;
    border-bottom: 1px solid $body-color;
    margin-bottom: 0.4em;
  }

  .letter-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 2em;
  }

  .letter-checkbox::before {
    content: "";
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 4px;
    border: 1px solid $body-color;
    margin-right: 0.6em;
    margin-bottom: 0.3em;
  }

  .top-border {
    border-top: 1px dashed $primary;
  }
}

.referral-letter {
  .headline {
    font-size: 1.3em;
  }
}
