:root {
  --color-red: red;
  --color-yellow: yellow;
  --color-orange: orange;
  --color-green: green;
  --color-blue: blue;
  --color-white: rgb(248 248 248);
}

.search-button-group {
  display: flex;
  gap: 1rem;

  input {
    outline: none;
    border: none;
    background: transparent;
    flex: 1;
    width: 80%;

    &::placeholder {
      color: $dark;
      margin-top: 3px;
      font-size: 17px;
    }
  }

  .eha-search {
    color: $teal;
    font-size: 15px;
    padding-left: 9px;
  }
}

.btn {
  border-radius: 4px;
}

.searching-box {
  width: 250px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #d9e9ee;
  background: transparent;
  background: #f6fafb;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: 17px;
}

.footer-custom-text {
  color: $primary;
  font-size: 14px !important;
  text-transform: lowercase;
}

.footer-dropdown-menu {
  border-radius: 10px;
  width: 90%;
  max-width: 90%;
}

.triage-header {
  font-size: 22px;
  font-weight: 700;
  padding-left: 4px;
}

.triage-table-head {
  background-color: #e5ebeb;
  color: #333333f3;
  font-size: 17px;
}

.custom-triage-tooltip .tooltip-inner {
  background-color: #314342;
  color: #fff;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
}

.custom-triage-tooltip .tooltip-arrow::before {
  border-top-color: #8ea7ad;
}

.wrap-triage-text {
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
}

.wrap-triage-assignment {
  white-space: normal;
  word-wrap: break-word;
  max-width: 300px;
}

.wrap-selected-branch-container {
  flex-wrap: wrap;
  gap: 3px;
  max-width: 100%;
}

.vertical-middle {
  vertical-align: middle;
}

.row-white {
  background-color: white;
}

.triage-row-alt {
  background-color: #f7fbfc;
}

.category-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.custom-text-color {
  color: #292928;
}

.triage-table-container {
  padding: 0 20px;
}

.category-bar {
  width: 70px;
  height: 6px;
  border-radius: 4px;
}

.category-1 {
  background-color: var(--color-red);
}

.category-2 {
  background-color: var(--color-yellow);
}

.category-3 {
  background-color: var(--color-orange);
}

.category-4 {
  background-color: var(--color-green);
}

.category-5 {
  background-color: var(--color-blue);
}

.category-6 {
  background-color: var(--color-white);
}

.triage-table {
  width: 100%;
  font-size: 13.5px;
}

.triage-capitalize {
  text-transform: capitalize;
}

.triage-table-footer {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.ellipsis-menu {
  font-size: 24px;
  color: #000;
  cursor: pointer;

  .ellipsis-cell {
    text-align: right;
  }
}

.ellipsis-menu.no-caret::after {
  display: none;
}

.ellipsis-menu:hover {
  color: #007bff;
}

.pagination-controls button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-controls button.active {
  font-weight: bold;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.go-to-page input {
  width: 80px;
  text-align: center;
}

.pagination-controls button.active-page {
  color: $primary;
  border: 1px solid $primary;
  border-radius: 20%;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
}

.categories-searchbar {
  padding-top: 18px;
  border-bottom: 1px solid #d2e3e7;
}

.rounded-container {
  border-radius: $border-radius-lg;
  background: $white;
  box-shadow: $box-shadow;
  position: relative;
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
}

.menu-color {
  width: 7px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.custom-dropdown-menu {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 4px;
  width: 90%;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-y: auto;
}

.custom-dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.custom-dropdown-toggle:hover {
  background-color: transparent !important;
  color: $primary !important;
}

.custom-dropdown-toggle:focus {
  box-shadow: none;
}

.custom-dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  background-color: transparent !important;
  border: none;
  color: #292928;
  white-space: normal;
  overflow-wrap: break-word;
  text-align: left;
  transition:
    background-color 0.8s ease,
    color 0.3s ease;
}

.custom-dropdown-toggle::after {
  border: solid transparent;
  border-width: 5px 5px 0;
  border-top-color: #000;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-dropdown-item {
  max-width: 100%;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.custom-drop-menu .dropdown-item::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #292928;
  margin-right: 10px;
  vertical-align: middle;
}

.custom-drop-menu {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  width: 90%;
  max-width: 100%;
  min-width: 120px;
}

.custom-drop-menu .dropdown-item {
  color: #333;
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.selected-branch {
  background-color: #c9dadfd5;
  color: #5a7574e5;
  padding: 5px 8px;
  margin-left: 5px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
}

.selected-branch:hover {
  background: #d9e9ee;
}

.appointment-checkbox {
  appearance: none;
  width: 15px;
  height: 14px;
  border: 1px solid #8f8f9d;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.appointment-checkbox:checked {
  background-color: #133ece;
  border: none;
}

.appointment-checkbox:hover {
  border: 2px solid #828291da;
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
}

.appointment-checkbox:checked::after {
  content: "✔";
  position: absolute;
  top: 53%;
  left: 54%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 15px;
}

.check-in-custom-button {
  padding: 10px 113px;
  font-size: 16px;
  border: 2px solid $primary;
  background-color: transparent;
  color: $primary;
  cursor: pointer;
  border-radius: 5px;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

.check-in-custom-button:hover {
  background-color: #41b5fdd5;
  color: white;
}

.color-bar {
  width: 560px;
  height: 6px;
  border-radius: 4px;

  &.red {
    background-color: var(--color-red);
  }

  &.yellow {
    background-color: var(--color-yellow);
  }

  &.orange {
    background-color: var(--color-orange);
  }

  &.green {
    background-color: var(--color-green);
  }

  &.blue {
    background-color: var(--color-blue);
  }

  &.white {
    background-color: var(--color-white);
  }
}

.question-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  font-size: 14px;
}

input.form-input:focus {
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: none;
}
