.test-info {
  background: rgba($light, 0.2);
}

.remove-item {
  font-size: 12px;
}

.remove-item:hover {
  background-color: rgba($red, 0.1);
}
