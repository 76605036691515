.dropdown-toggle::after {
  border: 0;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-radius: 1px;
  width: 0.5em;
  height: 0.5em;
  transform: rotate(45deg);
}

.dropdown-menu {
  box-shadow: $drop-shadow;
}

.autocomplete-search {
  position: relative;
  padding: 0;
  min-width: 16rem;

  .autocomplete-dropdown {
    position: absolute;
    border-radius: $border-radius-sm;
    box-shadow: $drop-shadow;
    background: white;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  .dropdown-items {
    overflow-y: auto;
    max-height: 60vh;
  }

  .autocomplete-dropdown-item {
    padding: 0.5rem;
    font-size: 0.94rem;

    .label {
      color: $gray-700;
    }

    &:hover {
      background: $gray-200;
      cursor: pointer;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $light;
    }
  }
}
