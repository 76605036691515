/* stylelint-disable */

.react-datepicker-popper .react-datepicker {
  background: $white;
  box-shadow: $drop-shadow;
  border: 1px solid $light;
  font-family: Overpass, sans-serif;
  font-size: 0.94rem;
  position: relative;
  display: flex;

  .react-datepicker__navigation {
    top: 1rem;
  }

  .react-datepicker__header {
    background-color: $gray-100;
    border-bottom: 1px solid transparent;
    height: 5rem;
    padding: 0.8rem 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .react-datepicker__current-month,
  .react-datepicker-year-header {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .react-datepicker-time__header {
    margin-top: auto;
  }

  .react-datepicker__day-name {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: $secondary-light;
    width: 2rem;
  }

  .react-datepicker__day {
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-container {
    border-left: 1px solid $light;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: $primary;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: $primary;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: $secondary;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: $primary;
    border-width: 2px 2px 0 0;
  }
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker
  .react-datepicker__triangle::before {
  border-bottom-color: $gray-400;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker
  .react-datepicker__triangle::after {
  border-bottom-color: $gray-100;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker
  .react-datepicker__triangle::before {
  border-top-color: $gray-500;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker
  .react-datepicker__triangle::after {
  border-bottom-color: $white;
}

.react-datepicker-wrapper .react-datepicker__close-icon::after {
  background: none;
  font-size: 1.4rem;
}

.react-datepicker .react-datepicker__triangle {
  left: -1rem !important;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.6rem 1rem 0.4rem;
  box-shadow: $box-shadow-inset;
  border-radius: $border-radius-sm;
  background: rgba($primary, 0.2);
  border: 1px solid transparent;
  margin-bottom: 1px;

  &.date-filter {
    background: lighten($purple, 3);
    color: $white;
    box-shadow: $box-shadow-sm;

    &::placeholder {
      color: rgba($white, 0.6);
    }
  }

  &:focus-within,
  &:focus-visible {
    background: rgba($primary, 0.3);
    border: 1px solid rgba($primary, 0.3);
    outline: none;
  }

  &:hover {
    background: rgba($primary, 0.3);
  }
}

/* stylelint-enable */
