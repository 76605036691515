.error-page {
  &::before {
    content: "";
    display: block;
    width: 100vw;
    height: 70vh;
    background: #41b5fd;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    z-index: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 20vh;
    z-index: 0;
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-bottom: 10vh solid #41b5fd;
    opacity: 0.2;
  }

  .error-page-top {
    height: calc(150px + 10vw);
  }

  .error-page-bottom {
    background: rgba($primary, 0.4);
    z-index: 1;
  }

  .error-page-number {
    font-size: calc(100px + 10vw);
    font-weight: 600;
    line-height: 0.5em;
    margin-left: 10%;
    color: rgba($white, 0.5);
  }

  .error-img {
    margin-top: -12vw;

    img {
      width: calc(140px + 16vw);
    }
  }
}
