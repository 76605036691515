.report-main {
  width: 600px;
  min-width: 600px;
  max-width: 600px;
  height: 700px;
  position: relative;
}

.report-component {
  background: $white url("/img/bg.jpg");
  padding: 1rem;
  max-width: 100%;
  overflow-x: hidden;
}

.tableau-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.report-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0 0 0 / 50%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
