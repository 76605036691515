$nav-bar-height: 60px;

.qapp-index-config-nuticon {
  position: absolute;
  width: 40%;
  bottom: 15px;
  left: 30px;
}

.qapp-index-config-person {
  position: absolute;
  width: 55%;
  bottom: -8px;
  right: 0;
}

.qapp-report-graph {
  position: absolute;
  width: 50%;
  top: -10px;
  left: 22px;
}

.qapp-report-person {
  position: absolute;
  width: 42%;
  top: 40px;
  right: 27px;
}

.qapp-nav-profile .dropdown-toggle {
  color: $primary !important;
}

.qapp-user-name > a::after {
  margin-top: 4px;
  margin-left: 12px;
}

.qapp-index-config-text {
  position: absolute;
  bottom: 15px;
  right: 10px;
  font-weight: 300 !important;
  color: $dark;
}

.qapp-main-body {
  position: relative;
  box-sizing: border-box;
  height: auto;
  max-width: 100vw;
}

.qapp-main {
  margin-left: 260px;
  width: calc(100% - 260px);
  min-height: 100vh;
  background: $white url("/img/bg.jpg");
}

.qapp-record-container {
  width: calc(100vw - 260px);
  padding: 0;
  min-height: 100vh;
  transform: translateX(260px);
}

// React select

/* stylelint-disable */

.select {
  &.select-filter {
    background: lighten($purple, 3);
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius-sm;

    .select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .select__single-value,
    .select__input-container {
      color: $white;
    }

    .select__indicator-separator {
      background: rgba($white, 0.2);
    }

    .select__indicator,
    .select__indicator:hover {
      color: $white;
    }

    .select__placeholder {
      color: rgba($white, 0.6);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.select-small {
    .select__control {
      min-height: 0;
    }
    .select__value-container {
      padding: 0.2rem 0 0.1rem 0.6rem;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__indicator {
      padding: 0 0.5rem;
    }
  }

  .select__control {
    border: 0;
    background: 0;
    box-shadow: none;
    height: auto;
  }

  .select__value-container {
    padding: 1rem 1.2rem 0.8rem;
  }

  .select__value-container--has-value.select__value-container--is-multi {
    padding-left: 1rem;
  }

  .select__input-container {
    padding: 0;
    margin: 0;
  }

  .select__input {
    margin: 0;
  }

  .select__placeholder {
    &::before {
      content: '';
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  .select__multi-value {
    background: $light;
  }

  .select__indicator,
  .select__indicator:hover {
    color: $primary;
  }

  .select__menu {
    margin-top: 1px;
  }

  .select__option--is-selected {
    background: $primary;
  }
}

.nrdform {
  background-color: $light;
}

.nrdform:focus {
  background-color: red !important;
}

.nrd-input {
  background-color: transparent;
  border: none;
  outline: none;
}

@media screen and (max-width: 768px) {
  .qapp-main {
    position: relative;
    width: 100%;
    transform: translateX(0px);
    margin-left: 0;
  }
}

