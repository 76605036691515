.dashboard-card {
  border-radius: $border-radius-lg;
  background: $white;
  box-shadow: $box-shadow;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .label {
    color: $gray-800;
  }

  .card-top {
    border-bottom: 1px solid $light;
    background: rgba($light, 0.2);
    padding: 0.6rem 1rem 0.4rem;
  }

  &.clickable:hover,
  .card-item.clickable:hover {
    outline: 2px solid rgba($secondary-light, 0.3);
  }

  .card-item:first-child {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
  }

  .card-item:last-child {
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
  }
}

.card-widget:not(.small-widget) {
  padding: 1rem;

  .label {
    margin-bottom: 1rem;
  }

  .figure {
    font-size: 1.8rem;
    font-weight: 600;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 576px) {
      font-size: 1.5rem;
    }
  }

  i {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 2rem;
  }
}

.card-widget.small-widget {
  padding: 0.6rem 1rem;
  min-width: 50%;
  display: flex;
  flex: 1;
  align-items: center;
  border-right: 1px solid $light;
  border-top: 1px solid $light;

  .figure {
    font-size: 1.2rem;
    font-weight: 600;
    order: 2;
    margin-left: auto;
  }

  i {
    font-size: 1.6rem;
    width: 3rem;
    display: block;
    order: 0;
  }

  .label {
    order: 1;
  }

  @include media-breakpoint-up(sm) {
    &:nth-child(even),
    &:last-child {
      border-right: none;
    }

    &:nth-child(2),
    &:first-child {
      border-top: none;
    }
  }

  @include media-breakpoint-down(sm) {
    min-width: 100%;
    border-right: none;
  }
}

.card-widget:not(:last-child) {
  border-right: 1px solid $gray-400;
}

.dashboard-card + .dashboard-card {
  margin-top: -0.8rem;
}
