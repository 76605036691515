.examination-container {
  background: $gray-100;
  height: 100%;
  display: flex;
  position: relative;

  @include media-breakpoint-down(lg) {
    display: block;
    height: auto;
    min-height: 100%;
  }
}

.examination-steps {
  width: 20vw;
  border-right: 1px solid $light;

  .side-nav-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  @include media-breakpoint-down(lg) {
    background: $white;
    border-radius: $border-radius;
    border: none;
    z-index: 100;
    position: sticky;
    top: 1rem;
    margin-left: 1rem;
    width: calc(100% - 2rem);
    box-shadow:
      0 1px 6px rgba($gray-900, 0.3),
      0 1rem 1rem 1rem rgba($gray-100, 0.6);
    height: auto;
    padding-bottom: 2rem;

    .nav-link {
      padding-left: 1rem;
      width: 100%;
    }

    &.collapsed {
      height: 3rem;
      padding-bottom: 0;

      .nav-link.active {
        position: absolute;
        top: 0.6rem;
      }

      .nav-link:not(.active),
      .nav-item {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}

.examination-step {
  padding: 0.3rem 1rem;
  font-weight: 600;
  font-size: 1rem;
  color: $body-color;
  cursor: pointer;
  display: flex;

  &.active {
    color: $primary;
  }
}

.examination-content {
  flex: 1;
  position: relative;
  background: $gray-100;

  .tab-content {
    height: 100%;
    overflow: auto;
    padding-bottom: 5rem;
  }
}

.examination-bottom {
  position: absolute;
  bottom: 0;
  background: $white;
  border-top: 1px solid $light;
  width: 100%;
  box-shadow: 0 -3px 3px rgba($gray-900, 0.05);
}
