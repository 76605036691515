.all-appointments {
  display: flex;
  flex-wrap: wrap;
}

.appointment-sidebar {
  width: 20vw;
  min-width: 18rem;
  max-width: 30rem;
  height: calc(100vh - $topnav-height);
  display: flex;
  flex-direction: column;

  .filter-toggle {
    display: none;
  }

  .appointment-filters {
    flex: 1;
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .appointment-sidebar {
    background: $white;
    border-bottom: 1px solid $light;
    width: 100vw;
    height: auto;
    max-width: none;

    .filter-toggle {
      display: inline-block;
      float: right;
    }

    &.collapsed {
      .appointment-filters {
        display: none;
      }
    }
  }
}
