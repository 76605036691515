.add-assessment-modal {
  .close-btn {
    color: white;
  }

  .modal-min-90 {
    width: 850px !important;
  }
}

.add-assessment-form-container {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .add-assessment-modal {
    .modal-min-90 {
      width: 350px !important;
    }

    .modal-dialog-centered {
      margin: auto;
    }
  }
}
