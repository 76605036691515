/* overpass-regular - latin */
@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/Overpass/overpass-v10-latin-regular.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../public/fonts/Overpass/overpass-v10-latin-regular.eot?#iefix")
    format("embedded-opentype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-regular.woff2")
    format("woff2"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-regular.woff")
    format("woff"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-regular.ttf")
    format("truetype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-regular.svg#Overpass")
    format("svg"); /* Legacy iOS */
}

/* overpass-600 - latin */
@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  src: url("../../../public/fonts/Overpass/overpass-v10-latin-600.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../public/fonts/Overpass/overpass-v10-latin-600.eot?#iefix")
    format("embedded-opentype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-600.woff2")
    format("woff2"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-600.woff")
    format("woff"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-600.ttf")
    format("truetype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-600.svg#Overpass")
    format("svg"); /* Legacy iOS */
}

/* overpass-700 - latin */
@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  src: url("../../../public/fonts/Overpass/overpass-v10-latin-700.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../public/fonts/Overpass/overpass-v10-latin-700.eot?#iefix")
    format("embedded-opentype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-700.woff2")
    format("woff2"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-700.woff")
    format("woff"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-700.ttf")
    format("truetype"),
    url("../../../public/fonts/Overpass/overpass-v10-latin-700.svg#Overpass")
    format("svg"); /* Legacy iOS */
}

/* asap-italic - latin */
@font-face {
  font-family: Asap;
  font-style: italic;
  font-weight: 400;
  src: url("../../../public/fonts/Asap/asap-v21-latin-italic.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("../../../public/fonts/Asap/asap-v21-latin-italic.eot?#iefix")
    format("embedded-opentype"),
    url("../../../public/fonts/Asap/asap-v21-latin-italic.woff2")
    format("woff2"),
    url("../../../public/fonts/Asap/asap-v21-latin-italic.woff") format("woff"),
    url("../../../public/fonts/Asap/asap-v21-latin-italic.ttf")
    format("truetype"),
    url("../../../public/fonts/Asap/asap-v21-latin-italic.svg#Asap")
    format("svg"); /* Legacy iOS */
}
