.all-reminders {
  .all-reminders-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 35px;

    .all-reminders-item {
      border-radius: 8px;
      border: 1px solid #d9e9ee;
      background: rgb(219 233 239 / 20%);
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .reminder-details {
        display: flex;
        flex-direction: column;

        .reminder-details-name-timing {
          display: flex;
          align-items: center;
          column-gap: 25px;
          flex-wrap: wrap;
          row-gap: 8px;

          .reminder-details-name {
            font-weight: 700;

            // font-size: 16px;
          }
        }

        .reminder-details-timing {
          display: flex;
          align-items: center;
          column-gap: 10px;
          row-gap: 8px;
          flex-wrap: wrap;

          .timing-header {
            font-size: 12px;
          }

          .eha-calendar {
            font-size: 11px;
          }

          .eha-circle {
            font-size: 5px;
          }
        }

        .reminder-appointment-details {
          display: flex;
          column-gap: 18px;
          row-gap: 10px;
          margin-top: 10px;
          font-size: 13.5px;
          flex-wrap: wrap;

          .appointment-header {
            font-family: Asap, sans-serif;
            font-size: 11px;
            font-style: italic;
          }

          .eha-circle {
            font-size: 5px;
          }
        }
      }

      .reminder-actions {
        display: flex;
        align-items: flex-start;
        column-gap: 15px;

        .second-action {
          display: flex;
          flex-direction: column;
        }

        .new,
        .overdue,
        .due,
        .reschedule,
        .cancel {
          padding: 3px 5px;
          font-size: 13px;
        }

        .new {
          color: #5acc97;
          background: #e9f2ee;
        }

        .overdue {
          color: $danger;
          background: #f4eaeb;
        }

        .due {
          color: #e5c415;
          background: #f2f1e9;
        }

        .reschedule {
          color: $primary;
          font-size: 14px;
          font-weight: 500;
        }

        .cancel {
          color: $danger;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .all-reminders {
    .all-reminders-list {
      .all-reminders-item {
        .reminder-actions {
          margin-top: 15px;

          .second-action {
            flex-direction: row;
          }
        }
      }
    }
  }
}
