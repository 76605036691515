.collapsible-filterbar {
  background: rgba($body-color, 0.1);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($body-color, 0.1);
  flex: 1;
  border-radius: 1.8rem;

  .filters-top {
    color: $white;
  }

  .filterbar-toggle {
    width: 4rem;
    margin-left: 1px;
    cursor: pointer;
    border-top-right-radius: 1.8rem;

    &:hover {
      background: rgba($secondary, 0.2);
    }
  }

  .filterbar-search {
    input {
      background: none;
      border: 0;
      color: $white;
      flex: 1;
      padding-right: 1.6rem;

      &:focus,
      &:focus-visible,
      &:active,
      &:hover {
        background: none;
        border: 0;
        outline: 0;
      }
    }

    input::placeholder {
      color: rgba($white, 0.5);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      border-bottom: 1px solid $purple;
    }
  }

  .filters-expand {
    background: rgba($body-color, 0.1);
    border-bottom-right-radius: 1.8rem;
    border-bottom-left-radius: 1.8rem;
  }

  &.collapsed {
    .filters-expand {
      display: none;
    }

    .filterbar-toggle {
      border-bottom-right-radius: 1.8rem;
    }
  }
}

.filter-badge {
  font-size: 0.9rem;
  color: $light;
  border-radius: $border-radius-sm;
  /* stylelint-disable */
  background: lighten($purple, 3);
  /* stylelint-enable */
  padding: 0.1rem 0.8rem;
  margin: 0.1rem 0 0.1rem 0.6rem;
}

.input-switch {
  position: relative;
  align-items: center;
  flex: 1;
}

.filter-reset-value {
  opacity: 0.4;
  position: absolute;
  right: 1.6rem;

  &:hover {
    opacity: 0.7;
  }
}

.search-menu {
  .search-toggle {
    color: $white;
  }
}
