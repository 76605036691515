.anc-table {
  width: 100%;
  max-width: 100%;

  thead {
    width: 100%;

    tr {
      padding: 8px 5px 4px;
      background-color: #e4e2e2;

      th {
        min-width: 100%;
        word-break: break-all;
      }
    }
  }

  .anc-table-element {
    tr {
      display: grid;
      grid-template-columns: 1fr 1fr 2.2fr 2.2fr 1fr;
    }
  }

  .vac-table-element {
    tr {
      display: grid;
      grid-template-columns: 0.8fr 1fr 1.5fr 1fr 1fr 2fr 1fr 1fr;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    tr {
      padding: 7px 5px;

      &.odd {
        background: rgba($light, 0.8);
      }

      .eha-edit {
        color: $secondary;
        cursor: pointer;
      }

      .eha-delete {
        color: $danger;
        cursor: pointer;
      }
    }
  }

  td {
    display: flex;
    align-items: center;
    min-width: 100%;
    word-break: break-all;
  }
}

.anc-table-switch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.anc-loader {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vac-table-1 {
  width: 100%;
  max-width: 100%;

  table {
    width: 100%;

    thead {
      tr {
        display: grid;
        grid-template-columns: 1.2fr 1fr 0.6fr 1fr 1fr 1fr 0.5fr 0.4fr;
        background-color: #e4e2e2;

        th {
          padding: 7px 4px;
          min-width: 100%;
          word-break: break-all;
          max-width: 100%;
        }
      }
    }

    tbody {
      .first-row {
        margin-top: 7px;
        padding-bottom: 15px;
        border-bottom: 2px solid $light;
      }

      tr {
        display: grid;
        grid-template-columns: 1.2fr 5.5fr;
      }
    }

    .first-cell {
      padding: 7px 4px;
    }

    .second-cell {
      .odd {
        background: rgba($light, 0.8);
      }

      tr {
        display: grid;
        grid-template-columns: 1fr 0.6fr 1fr 1fr 1fr 0.5fr 0.4fr;

        td {
          padding: 7px 4px;
          max-width: 100%;
          min-width: 100%;
          word-break: break-all;

          .dose-num {
            padding-left: 25%;
          }

          .eha-edit {
            color: $secondary;
            cursor: pointer;
          }

          .eha-delete {
            color: $danger;
            cursor: pointer;
          }
        }
      }
    }
  }
}
