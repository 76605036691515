// Include ICON font
// (to update the icons, upload config.json to fontello.com, edit icons and export again)

@import "../../../public/fontello/css/ehaclinics.css";

// Tweak Boostrap variables before importing bootstrap

@import "bootstrap-variables";
@import "~bootstrap/scss/mixins";
@import "fonts";
@import "globals";
@import "button";
@import "ehr-form";
@import "dropdown";
@import "modal";
@import "accordion";
@import "datepicker";
@import "calendar";
@import "tabs";
@import "letter";
@import "error-pages";
@import "layout";
@import "reportsLayout";
@import "actionMenu";
@import "loading";
@import "toaster";
@import "form";
@import "filterSelect";
@import "filter";
@import "flowStatus";
@import "card";
@import "charts";
@import "table";
@import "sideNav";
@import "document";
@import "chatter";
@import "print";
@import "react-phone-number-input/style.css";
@import "notification";
@import "landing";
@import "adminLayout";
@import "../../../public/better-web-components/form-renderer/styles.css";
@import "../../../public/better-web-components/form-renderer/styles-theme.css";
@import "../../ambulatory/features/anc/styles/index";
@import "../../ambulatory/features/bmgf/styles/index";
@import "../../ambulatory/features/encounter/styles/index";
@import "../../ambulatory/features/idoven/styles/index";
@import "../../ambulatory/features/labtest/styles/index";
@import "../../ambulatory/features/patient/styles/index";
@import "../../ambulatory/features/vaccination/styles/index";
@import "../../chat-app/main-chat-app/styles/index";
@import "../../chat-app/mini-chat-app/styles/index";
@import "../../appointment/features/appointment/styles/index";
@import "../../appointment/features/triage/styles/index";
@import "../../patient-flow/features/patient-flow/styles/index";
@import "../../quality-app/common/styles/index";
@import "../../settings/common/styles/index";
