.bar-container {
  width: 100%;
  position: relative;

  .bar-bg {
    width: 100%;
    height: 10px;
    background: $gray-300;
    border-radius: 10px;
  }

  .bar {
    height: 10px;
    position: absolute;
    top: 0;
    border-radius: 10px;
  }

  .scale {
    display: flex;
    justify-content: space-between;
  }
}

.recharts-wrapper {
  font-size: 0.8rem;
}

.recharts-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: $white;
  box-shadow: $drop-shadow;
  border-radius: $border-radius;

  .value {
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
  }

  .date {
    display: inline-block;
  }
}
