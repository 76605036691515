.mc-sidebar {
  width: 385px;
  max-width: 385px;
  height: 100%;
  max-height: 100%;
  background-color: #5b74c6;
  box-shadow: 2px 0 3px 0 rgb(147 167 166 / 28%) inset;
  display: flex;
  flex-direction: column;

  .sidebar-header {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 23px;
    border-top: 0.7px solid;
    border-color: rgb(217 233 238 / 20%);
  }

  .sidebar-close-btn {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 23px;
    width: 100%;

    button {
      display: flex;
      justify-content: flex-start;
      column-gap: 10px;
      font-size: 17px;
      cursor: pointer;
    }
  }

  .mc-accordion-section {
    flex: 1;
    width: 100%;
    overflow-y: scroll;

    &.styled-scrollbar {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #5b74c6;
      }

      &::-webkit-scrollbar-thumb {
        background: #9f9f9f;
        border-radius: 4px;
      }
    }
  }

  .sidebar-navitem-parent {
    padding: 13px 23px;
    border-top: 0.7px solid;
    border-color: rgb(217 233 238 / 20%);
  }

  .sidebar-navitem {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: $white;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgb(255 255 255 / 16%);
    }

    &.selected-sidebar-navitem {
      background-color: rgb(255 255 255 / 16%);
    }
  }

  .ms-plus-icon {
    padding: 1px;
    border-radius: 100%;
    margin-top: 14px;
    background-color: rgb(255 255 255 / 22%);
  }

  .ms-messages {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    color: $white;

    li {
      padding: 0 13px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 16px;
      column-gap: 10px;
      border-radius: 10px;
      cursor: pointer;
      color: white !important;

      &.ms-item-selected {
        background: rgb(0 0 0 / 16%);
      }

      .ms-image-and-name {
        display: flex;
        align-items: center;
        column-gap: 10px;
        flex-grow: 1;

        .name-initials {
          width: 28px;
          height: 28px;
          font-size: 12px;
          border-radius: 100%;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: white;
        }

        //    For the image parent element
        .ms-profile-img {
          position: relative;

          .ms-channel-hash {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }

          img {
            border-radius: 100%;
            height: 25px;
            width: 25px;
            max-width: 25px;
            max-height: 25px;
            object-fit: cover;
          }

          .status-dot {
            position: absolute;
            right: 0;
            top: 20px;
            width: 7px;
            height: 7px;
            border-radius: 100%;
            z-index: 10;

            &.active {
              background-color: $green;
            }

            &.inactive {
              background-color: $purple;
            }
          }
        }
      }

      &:hover {
        background: rgb(0 0 0 / 4%);
      }
    }
  }

  .ms-patient-header {
    margin: auto;
    color: $white;
    font-size: 12px !important;
    padding: 3px 14px;
    margin-top: 20px;
  }
}

.message-count-badge {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $dark;
  font-size: 12px;
  border-radius: 100%;
  padding-top: 3px;
}

@media screen and (max-width: 992px) {
  .mc-sidebar {
    width: 260px;

    .sidebar-header {
      padding: 0 10px;
      border-top: 0.7px solid;
      border-color: rgb(217 233 238 / 20%);
    }

    .sidebar-navitem-parent {
      padding: 13px 10px;
    }

    .sidebar-navitem {
      padding: 0 8px;
    }

    .ms-messages {
      li {
        padding: 0 8px;
      }
    }

    .ms-patient-header {
      padding: 3px 8px;
    }
  }
}

// Sidebar disappears and is replaced by a mobile sidebar
@media screen and (max-width: 768px) {
  .mc-sidebar {
    display: none;
  }
}

// Plus icons dropdown classes
.plus-icon-dropdown {
  position: relative;

  .plus-icon-dropdown-menu {
    position: absolute;
    z-index: 10;
    padding: 15px 10px;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 160px;
    transform: translateX(-80%);
  }

  .plus-icon-menu-item {
    color: $dark;
    font-size: 14px;
    border-radius: 8px;
    padding: 5px 10px;

    &:hover {
      background-color: $gray-400;
    }
  }
}

.dropdown.new-message-action-menu {
  width: 100% !important;
  background: transparent !important;
  border: 0;

  .btn-primary.dropdown-toggle {
    color: white !important;
  }

  .dropdown-toggle {
    width: 100% !important;
    font-size: 16px !important;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 10px 15px;
  }

  .dropdown-menu.show {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
    padding: 15px !important;
  }

  .dropdown-item {
    &:active {
      background-color: $gray-400 !important;
      color: $dark !important;
    }
  }
}

@supports (scrollbar-color: #9f9f9f #5b74c6) {
  .mc-accordion-section {
    &.styled-scrollbar {
      scrollbar-color: #9f9f9f #5b74c6;
      scrollbar-width: thin;
    }
  }
}
