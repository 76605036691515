.accordion-form {
  .accordion-item {
    background: none;
  }

  .accordion-item:not(:first-child) {
    border-top: 1px solid $light;
  }

  .accordion-header {
    .accordion-button {
      font-size: 1.1rem;
      font-weight: 600;
      padding: 1.5rem 2rem;
      background: none;
      box-shadow: none;
    }
  }
}
