.settings-dashboard {
  width: 100%;
  height: 100%;
  padding: 40px 20px;
}

.settings-dashboard-title {
  font-weight: 600;
  color: $dark;
  border-bottom: 1.5px solid #c3d2d8;
}

.settings-dashboard-transition {
  margin-top: 20px;

  h6 {
    font-size: 15px;
    font-weight: 600;
  }
}

.settings-patient-transition {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  &.checked {
    background: $primary;
  }
}

// ANC Config styles
.anc-schedules-main {
  width: 95%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.anc-add-details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  align-self: flex-end;
  column-gap: 10px;
}

.anc-schedules-header {
  display: flex;
  column-gap: 20px;
}

.anc-add-more {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  padding: 0 !important;
}

@media screen and (max-width: 1200px) {
  .anc-schedules-main {
    width: 100%;
  }
}
