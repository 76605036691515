/***  Styles for Modals  ***/

/************  Add member modal  *****************/
.add-member-modal {
  .modal-min-90 {
    width: 560px !important;
  }

  .addmember-search {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 7px 10px;
    background-color: rgb(217 233 238 / 24%);
    border-radius: 5px;

    input {
      flex: 1;
      border: none;
      outline: none;

      // border: 1px solid red;
      background: transparent;

      &::placeholder {
        font-style: italic;
        font-size: 13px;
        font-family: Asap, sans-serif;
      }
    }
  }

  .addmember-item {
    width: 100%;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;

    &.selected {
      background-color: rgb(182 222 235 / 24%);
    }

    .addmember-item-image {
      position: relative;

      img {
        width: 26px;
        height: 26px;
        border-radius: 100%;
        object-fit: cover;
      }

      .member-status {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: rgb(139 138 138);
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &:hover {
      background-color: rgb(217 233 238 / 24%);
    }
  }

  .eha-check {
    color: green;
    size: 15px;
  }

  .addmember-name {
    flex: 1;
  }
}

/***********  Create channel modal ***************/

.create-channel-modal {
  .modal-min-90 {
    width: 560px !important;
  }

  .channel-error {
    padding: 10px;
    background-color: $danger;
    color: white;
    font-size: 12px;
    border-radius: 3px;
  }

  .channel-name {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &:nth-child(2) {
      margin-top: 30px;
    }

    label {
      font-size: 16px;
    }

    .channel-name-input {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #d9e9ee;
      padding: 0 13px;
      height: 47px;
      column-gap: 8px;
      border-radius: 7px;

      input[type="text"] {
        flex: 1;
        border: none;
        outline: none;
        background: transparent;

        // border: 1px solid red;

        &::placeholder {
          font-size: 14px;
        }

        &:focus {
          border: none;
          outline: none;
          background: transparent;
        }
      }
    }
  }

  .create-channel-button {
    background-color: $purple;

    &.btn-primary {
      border: none;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

/********* Leave Channel Modal **********/
.leave-channel-modal {
  padding-bottom: 10px;
  font-family: Overpass, sans-serif;

  .modal-min-90 {
    width: 560px !important;
  }

  .leave-header-text {
    font-size: 19px;
  }

  .leave-body-text {
    font-size: 16px;
    color: $gray-800;
  }

  .leave-modal-cancel-button {
    background: #d9e9ee;
    color: $dark;
    border: none;

    &.btn-primary {
      &:focus {
        box-shadow: 0 0 0 0.15rem rgb(217 233 238 / 100%) !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .add-member-modal {
    .modal-min-90 {
      width: 350px !important;
    }

    .modal-dialog-centered {
      margin: auto;
    }
  }

  .create-channel-modal {
    .modal-min-90 {
      width: 350px !important;
    }

    .modal-dialog-centered {
      margin: auto;
    }
  }

  .leave-channel-modal {
    margin: auto;

    .modal-min-90 {
      width: 350px !important;
    }

    .modal-dialog-centered {
      margin: auto;
    }
  }
}

// Create new template modal styles
.new-template-body {
  min-height: 150px;
}
