/* stylelint-disable selector-class-pattern */
.settings-checkbox {
  border: 0.5px solid $dark;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.sm {
    width: 12px;
    height: 12px;

    .eha-check {
      font-size: 8px;
    }
  }

  &.md {
    width: 14px;
    height: 14px;

    .eha-check {
      font-size: 10px;
    }
  }

  .eha-check {
    color: white;
    font-weight: 600;
  }

  &.checked {
    background: $primary;
    border: none;
  }
}

.anc-switch {
  min-width: 30px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0 2px;

  &.on {
    justify-content: flex-end;
    background-color: $primary;
  }

  &.off {
    justify-content: flex-start;
    background-color: $gray-700;
  }
}

.anc-switch-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

.anc-table-switch {
  display: flex;
  align-items: center;
  padding-left: 12%;
}

.anc-config-schedules-inputs {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 10px;
}

.anc-form-input {
  margin-top: 10px;
}

.anc-input-container {
  width: 100%;
  border: 1px solid $gray-700;
  background-color: #f7fbfc;
  border-radius: 6px;
  height: 40px;
}

.anc-week-input {
  flex: 1;
}

.anc-expect-input {
  flex: 1.2;
}

.anc-type-input {
  flex: 1.5;
}

.anc-expect-input,
.anc-type-input {
  input {
    width: 100%;
    outline: none;
    background: transparent;
    height: 35px;
    border: none;
    padding: 0 7px;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.vac-input {
  flex: 1;

  input {
    width: 100%;
    outline: none;
    background: transparent;
    height: 35px;
    border: none;
    padding: 0 7px;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.vaccination-config-schedules-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

.vaccination-input {
  flex: 1;

  input {
    width: 100%;
    outline: none;
    background: transparent;
    height: 35px;
    border: none;
    padding: 0 7px;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.vaccination-select-input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: none;
}

@media screen and (max-width: 992px) {
  .vac-input {
    flex: none;
    width: 200px;
  }
}

@media screen and (max-width: 576px) {
  .vaccination-config-schedules-inputs {
    grid-template-columns: 1fr;
  }

  .anc-config-schedules-inputs {
    row-gap: 0;
    margin-bottom: 25px;
  }

  .anc-week-input,
  .anc-expect-input,
  .anc-type-input {
    flex: none;
    width: 100%;
  }

  .vac-input {
    flex: none;
    width: 100%;
  }
}

.anc-select {
  &.anc-select-filter {
    background-color: transparent;

    .anc-select__indicator-separator {
      background: rgba($white, 0.2);
      display: none;
    }

    .anc-select__indicator,
    .anc-select__indicator:hover {
      color: $primary;
    }

    .anc-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.anc-select-small {
    .anc-select__control {
      min-height: 0;
    }

    .anc-select__indicator-separator {
      display: none;
    }

    .anc-select__indicator {
      padding: 0 0.5rem;
    }
  }

  .anc-select__control {
    border: 0;
    background: 0;
    box-shadow: none;
    height: auto;
  }

  .anc-select__value-container--has-value.anc-select__value-container--is-multi {
    padding-left: 1rem;
  }

  .anc-select__input-container {
    padding: 0;
    margin: 0;
  }

  .anc-select__input {
    margin: 0;
  }

  .anc-select__placeholder {
    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  .anc-select__multi-value {
    background: $light;
  }

  .anc-select__indicator,
  .anc-select__indicator:hover {
    color: $primary;
  }

  .anc-select__menu {
    margin-top: 1px;
  }

  .anc-select__option--is-selected {
    background: $primary;
  }
}
