.dashboard-column {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  position: relative;
}

.droparea {
  background: rgba($info, 0.1);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($info, 0.1);
  border-radius: $border-radius;
  min-height: 3rem;
  margin: 0 0 0.5rem;
  position: relative;

  &.dragging-over {
    background: rgba($info, 0.4);
    border: 1px solid rgba($primary, 0.2);
  }

  &.needs-cleaning {
    background: rgba($danger, 0.2);

    &::after {
      content: "needs cleaning";
      display: block;
      width: 100%;
      padding-left: 0.5rem;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: $danger;
      letter-spacing: 0.05em;
    }
  }

  .clean-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.dropable-item {
  margin: 0.5rem;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
  background: white;
  padding: 0.5rem;

  &:hover {
    box-shadow: $box-shadow-lg;
    background: $blue-light;
  }
}

.fullscreen {
  .dropable-item {
    .transition-icon {
      display: none;
    }
  }
}

.dashboard-column .dropable-item {
  padding: 0.5rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .icon {
    width: 1.3rem;
    display: inline-block;
  }

  .board-ticket-header {
    display: flex;
    align-items: baseline;
  }

  .board-patient {
    padding-left: 0.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .board-provider {
    padding-right: 0.5rem;
    line-height: 1.2em;
  }

  .board-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .board-time-clinic {
    color: $gray-700;
  }

  .time-in-clinic {
    font-size: 0.875rem;
  }

  .board-ticket-info:not(:empty) {
    display: flex;
    align-items: baseline;
  }

  &:hover {
    box-shadow: $box-shadow-lg;
    background: $blue-light;
  }
}

.column-layout .dashboard-column {
  @media screen and (min-width: 991px) {
    flex: 1;
  }

  .dropable-item {
    .board-ticket-header {
      width: 100%;
    }

    .board-provider {
      margin-left: auto;
      padding-right: 0.5rem;
      text-align: right;
    }

    .board-status {
      border-top: 1px solid $light;
      padding: 0.3rem 0.5rem 0;
      width: 100%;
    }

    .board-ticket-info:not(:empty) {
      border-top: 1px solid $light;
      width: 100%;
      padding: 0.3rem 0.5rem 0;
      margin-top: 0.3rem;
      flex-wrap: wrap;
    }

    .transition-icon {
      background: $primary;
      border-radius: $border-radius;
      padding: 0.3rem 0.2rem;
      color: $white;
      position: absolute;
      bottom: 0.3rem;
      right: 0.3rem;
      line-height: 0;
      cursor: pointer;
      display: none;
      box-shadow: $box-shadow;

      &:hover {
        background: $secondary;
      }
    }

    &:hover {
      .transition-icon {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .dropable-item {
    .board-ticket-header {
      width: 100%;
    }

    .board-provider {
      margin-left: auto;
      padding-right: 0.5rem;
      text-align: right;
    }

    .board-status {
      border-top: 1px solid $light;
      padding: 0.3rem 0.5rem 0;
      width: 100%;
    }

    .board-ticket-info:not(:empty) {
      border-top: 1px solid $light;
      width: 100%;
      padding: 0.3rem 0.5rem 0;
      margin-top: 0.3rem;
      flex-wrap: wrap;
    }

    .transition-icon {
      background: $primary;
      border-radius: $border-radius;
      padding: 0.3rem 0.2rem;
      color: $white;
      position: absolute;
      bottom: 0.3rem;
      right: 0.3rem;
      line-height: 0;
      cursor: pointer;
      display: none;
      box-shadow: $box-shadow;

      &:hover {
        background: $secondary;
      }
    }

    &:hover {
      .transition-icon {
        display: block;
      }
    }
  }
}

.dashboard-columns:not(.column-layout) {
  .dashboard-column {
    padding-bottom: 0;
  }

  @media screen and (min-width: 990px) {
    .dashboard-column {
      & > .label {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
    }

    .droparea {
      padding-left: 12rem;
      position: relative;
      min-height: 2rem;
      margin-bottom: 0.1rem;

      & > .label {
        width: 12rem;
        position: absolute;
        left: 0;
        font-size: 0.8em;
      }

      &.needs-cleaning::after {
        padding-top: 0.3rem;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -1px;
        height: 100%;
        width: 12rem;
        border-right: 1px solid rgba($white, 0.5);
      }
    }

    .dashboard-column.waiting-room .label[title="Waiting Rooms"] {
      display: none;
    }

    .dashboard-column.waiting-room {
      margin-top: 2rem;
    }

    .dashboard-column.other-rooms,
    .dashboard-column.exam-rooms {
      & > .label {
        display: none;
      }
    }

    .dropable-item {
      margin: 0.2rem;

      .board-ticket-header {
        width: 40%;
        display: flex;
        order: 1;
      }

      .board-patient {
        width: 50%;
      }

      .board-provider {
        width: 50%;
      }

      .board-status {
        order: 3;
        margin-left: auto;
        margin-right: 0.5rem;
      }

      .board-ticket-info {
        order: 2;
      }

      .board-time-clinic {
        margin-left: 0.5rem;
      }

      .transition-icon {
        color: $primary;
        cursor: pointer;
        margin-left: 0.3rem;
      }
    }
  }
}

.appointments-column {
  margin: 0.5rem 0.5rem 0.5rem 0;
  width: 20vw;
  min-width: 18rem;
  max-width: 30rem;
  overflow-y: auto;
  position: relative;
  transition:
    width 0.1s,
    min-width 0.1s;

  @media screen and (max-width: 576px) {
    width: 100vw;
    max-width: none;
  }

  .appointment-slider {
    background: $info;
    box-shadow: $box-shadow-sm;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .no-appointment {
    margin: 0.5rem;
  }

  &.collapsed {
    width: 45px;
    min-width: 0;
    flex-shrink: 0;

    .appointments-container,
    .no-appointment,
    .appointment-search {
      display: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.progress-bar {
  border-left: 1px solid white;
  margin-left: -1px;
}

.history {
  .history-item {
    margin-left: 4rem;
    padding-top: 0.6rem;
    margin-top: 0.3rem;
    position: relative;
    font-size: 0.9rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      border-left: 0.3rem dotted $gray-300;
      top: 0;
      bottom: 0;
      left: -2.8rem;
    }

    &:first-child {
      &::before {
        top: 2rem;
      }
    }

    &:last-child {
      &::before {
        bottom: auto;
        height: 2rem;
      }
    }
  }

  .history-icon {
    position: absolute;
    left: -4rem;
    border-radius: 100%;
    width: 2.6rem;
    height: 2.6rem;
    background: $gray-200;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $purple;
    font-size: 1.2rem;
  }

  .history-content {
    background: $gray-300;
    font-size: 0.9rem;
    padding: 0.4rem 1rem;
    position: relative;
    border-radius: $border-radius;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 10px solid $gray-300;
      position: absolute;
      left: -10px;
      top: calc(1.3rem - 8px);
    }
  }
}

.history-progress {
  height: 1.2rem;

  .progress-bar {
    padding-top: 0.1rem;
  }
}
