.table-light {
  width: 100%;

  thead,
  tbody {
    width: 100%;
  }

  thead {
    background: rgba($light, 0.4);
  }

  th,
  td {
    padding: 1rem 0.8rem 0.5rem;
  }

  td {
    padding: 0.5rem 0.8rem 0.3rem;
    border-bottom: 1px solid $light;
  }

  th {
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.9rem;
  }

  &.row-borders-top {
    td {
      border-top: 1px solid $light;
      border-bottom: 0;
    }
  }
}

.eha-table {
  width: 100%;

  thead,
  tbody {
    width: 100%;
    padding: 1rem 1.5rem;
  }

  thead {
    background: $purple;
    color: white;
    box-shadow: $box-shadow;
    z-index: 1;
    padding: 0.5rem 1.5rem;
  }

  tbody {
    tr {
      background: white;
      margin-top: 2px;
      border-radius: $border-radius-sm;
    }
  }

  th,
  td {
    padding: 0.7rem 0.8rem 0.5rem;
    vertical-align: top;
    overflow: hidden;
  }

  th {
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
  }

  td.name {
    font-weight: 600;
  }
}

.pagination-bar {
  background: $purple;
  color: white;
  padding: 0.7rem 1.5rem 0.5rem;
}
