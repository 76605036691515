.ec-landing-main {
  min-height: calc(100vh - $topnav-height);
  background: $white url("/img/bg.jpg");
  background-size: cover;
  margin-top: $topnav-height;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 30px;
}

.ec-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 40px;
  column-gap: 40px;
}

.ec-landing-card {
  cursor: pointer;
  width: 230px;
  height: 280px;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 6px rgb(64 83 82 / 10%), 1px 1px 1px rgb(127 139 170 / 20%), 0 0 2px rgb(255 255 255 / 30%) inset;
  display: flex;
  flex-direction: column;
}

.ec-card-label {
  font-family: Asap, sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 13.5px;
  color: $gray-800;
  line-height: normal !important;
}

.ec-card-svgs {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  row-gap: 30px;
}

.ec-card-main-logo-container,
.ec-card-footer-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ec-card-main-logo-container {
  width: 100%;
  height: 140px;
  max-width: 100%;
  max-height: 140px;
  position: relative;
}

.ec-report-graph {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 10px;
  left: -18px;
}

.ec-report-person {
  position: absolute;
  width: fit-content;
  height: fit-content;
  right: -17px;
  top: 50px;
}

.ec-config-nuticon {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 10px;
  left: -10px;
}

.ec-config-person {
  position: absolute;
  width: fit-content;
  height: fit-content;
  right: -17px;
  top: 30px;
}

.ec-config-footer {
  width: fit-content;
  position: relative;
}

.ec-config-text {
  position: absolute;
  font-weight: 300 !important;
  color: $dark;
  bottom: -7px;
  right: 27px;
  font-size: 13.5px;
}

.ec-messaging-text {
  position: absolute;
  font-weight: 300 !important;
  color: $dark;
  bottom: -7px;
  right: 20px;
  font-size: 13.5px;
}

@media screen and (max-width: 1100px) {
  .ec-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 850px) {
  .ec-card-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 550px) {
  .ec-card-container {
    grid-template-columns: 1fr;
  }
}
