.mca-layout {
  position: fixed !important;
  top: 55px;
  right: 85px;
  width: 385px;
  cursor: default;
  border: 1px solid $purple;
  background-color: white;
  z-index: 20 !important;
  border-radius: 8px;
  box-shadow: 2px 0 3px 0 rgb(147 167 166 / 28%) inset;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.mca-header {
  background-color: $indigo;
  height: 50px;
  display: flex;
  color: white !important;
  justify-content: space-between;
  padding: 10px 12px;

  .fullscreen {
    align-self: center;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 3px;
    text-transform: capitalize;
    color: $green;
  }

  button {
    align-self: flex-start;
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .mca-layout {
    width: 340px;
    right: 5px;
  }
}

/** Mini ChatApp Nav Section **/

.mca-nav {
  flex: 1;
  width: 100%;
  background-color: $indigo;
}

.mca-navheader {
  width: 100%;
  border-top: 1px solid rgb(217 233 238 / 16%);
  border-bottom: 1px solid rgb(217 233 238 / 16%);
  padding: 15px 20px;

  .dropdown.mca-new-message {
    width: 100% !important;
    background: transparent !important;
    border: 0;

    &:hover {
      .dropdown-menu {
        display: none;
      }

      .dropdown-menu.show {
        display: block;
      }
    }

    .btn-primary.dropdown-toggle {
      color: white !important;
    }

    .dropdown-toggle {
      width: 100% !important;
      font-size: 14px !important;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      margin-top: 6px;
    }

    .dropdown-menu.show {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 15px;
      padding: 10px 15px !important;
    }

    .dropdown-item {
      font-size: 14px;
      padding: 8px 13px;
      border-radius: 4px;
      text-transform: none;

      &:active {
        background-color: $gray-400 !important;
        color: $dark !important;
      }
    }
  }
}

.mca-accordion-section {
  flex: 1;
  width: 100%;
  height: fit-content;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #5b74c6;
    }

    &::-webkit-scrollbar-thumb {
      background: #9f9f9f;
      border-radius: 4px;
    }
  }
}

.mca-navitem-parent {
  border-top: 1px solid rgb(217 233 238 / 6.4%);
  padding: 14px 20px;
}

.mca-navitem {
  display: flex;
  align-items: center;
  color: white;
  justify-content: flex-start;
  column-gap: 6px;
  padding: 0 12px;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 15px;

  &.selected-navitem {
    background-color: rgb(255 255 255 / 16%);
  }
}

.mca-collapse {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  color: $white;

  li {
    padding: 0 13px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: none !important;
    column-gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: white !important;

    &:hover {
      background: rgb(0 0 0 / 4%);
    }
  }
}

.mca-image-and-name {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-grow: 1;

  .mca-name-initials {
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 100%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
  }
}

.mca-profile-img {
  position: relative;

  img {
    border-radius: 100%;
    height: 25px;
    width: 25px;
    max-width: 25px;
    max-height: 25px;
    object-fit: cover;
  }

  .status-dot {
    position: absolute;
    right: 0;
    top: 20px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    z-index: 10;

    &.active {
      background-color: $green;
    }

    &.inactive {
      background-color: $purple;
    }
  }
}

.mca-channel-hash {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.mca-count-badge {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $dark;
  font-size: 12px;
  border-radius: 100%;
  padding-top: 3px;
}

.mca-patient-header {
  display: flex;
  margin: auto;
  color: $white;
  font-size: 13px !important;
  padding: 3px 14px;
  margin-top: 20px;
}

/** Mini ChatApp Layout Section - For the MinChatAppLayout comp that houses channels, messages and reminder **/

.mca-section-layout {
  width: 100%;
  max-width: 100%;
  text-transform: none;
}

.mca-section-layout-header {
  height: 55px;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $dark;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #dbe9ef;

  .eha-dots {
    transform: rotate(90deg);
  }
}

.mca-name-initials {
  width: 28px;
  height: 28px;
  font-size: 12px;
  border-radius: 100%;
  border: 1px solid $gray-900;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: $dark;
}

.mca-section-layout-body {
  height: 70vh;
  max-height: 70vh;
}
