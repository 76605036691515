.nav-tabs {
  .nav-link {
    font-size: 1.1rem;
    font-weight: 600;
    border: 0;
    padding: 0.5rem 2rem;

    &.active {
      border-bottom: 2px solid $body-color;
      color: $body-color;
      background: 0;
    }
  }

  .nav-link[amount] {
    &::after {
      content: attr(amount);
      display: inline-block;
      color: $white;
      background: $secondary;
      padding: 0.3rem 0.4rem 0.1rem;
      font-size: 0.75em;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 6px;
      margin-left: 0.3rem;
    }
  }

  .nav-link[status="success"] {
    &::after {
      background: $success;
    }
  }

  .nav-item:first-child {
    .nav-link {
      padding-left: 0;
    }
  }
}

.nav-pills {
  .nav-link {
    font-size: 1rem;
    text-transform: uppercase;
    background: white;
    margin-right: 0.6em;
  }

  .nav-link[icon="file"] {
    &::before {
      font-family: ehaclinics, sans-serif;
      content: "\e879";
      display: inline-block;
      margin-right: 0.4em;
    }
  }

  .nav-link[icon="note"] {
    &::before {
      font-family: ehaclinics, sans-serif;
      content: "\e82c";
      display: inline-block;
      margin-right: 0.4em;
    }
  }
}
