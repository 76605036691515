.side-nav {
  width: 22vw;
  min-width: 15rem;
  max-width: 30rem;
  border-right: 1px solid white;
  overflow-y: auto;

  .side-nav-toggle {
    position: absolute;
    display: flex;
    top: 0.5rem;
    right: 0;
    color: $white;
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  @media screen and (min-width: 769px) {
    .side-nav-toggle {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    background: $purple;
    border: none;
    z-index: 100;
    top: 0;
    box-shadow: 0 1px 6px rgba($gray-900, 0.3);
    overflow: auto;
    width: 100%;
    max-width: 100vw;
    cursor: pointer;

    .side-nav-content {
      color: $white;
    }

    &.collapsed {
      height: 4rem;
      padding-top: 4rem;
      overflow: hidden;

      .side-nav-item.active,
      .nav-link.active {
        position: absolute;
        top: 0.6rem;
        background: none;
        box-shadow: none;
        color: $white;
        margin: 0;
        padding-left: 0.5rem;

        &::before {
          display: none;
        }

        .side-nav-icon {
          margin-left: 0;
          margin-right: 0.3rem;
          position: relative;
        }

        .badge {
          position: relative;
          left: 0.5rem;
        }
      }

      .nav-link.active {
        top: 0.9rem;
      }
    }
  }
}

.side-nav-item {
  font-weight: 600;
  padding: $spacer * 0.8 $spacer;
  background: rgba($white, 0.4);
  border-radius: $border-radius-lg;
  margin: $spacer * 0.5 0;
  position: relative;
  padding-left: $spacer * 2;
  transition: box-shadow 0.4s;
  display: flex;
  align-items: baseline;
  cursor: pointer;

  &::before {
    content: "";
    width: 1rem;
    height: 1rem;
    background: $white;
    transform: rotate(-45deg);
    position: absolute;
    right: -0.4rem;
    top: calc(50% - 0.5rem);
    opacity: 0;
    transition: box-shadow 0.4s;
  }

  .side-nav-icon {
    margin-left: -1.6rem;
    position: absolute;
  }

  &.active,
  &:hover {
    background: $white;
    box-shadow: $box-shadow;
    color: $primary;

    &::before {
      box-shadow: 2px 2px 2px rgba($gray-900, 0.1);
      opacity: 1;
    }
  }
}

.eha-sidenav {
  .nav-link {
    text-transform: uppercase;
    letter-spacing: 0.06em;
    padding: 0.2rem 0;

    &.active {
      color: $body-color;
    }
  }
}
