.config-main {
  width: 100%;
}

.config-layout {
  width: 100%;
  position: relative;
}

.config-layout-body {
  width: 100%;
  margin-top: $topnav-height;
  position: relative;
}

.settings-loading {
  width: 100%;
  height: (calc(100vh - $topnav-height));
  background: $white url("/img/bg.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.config-layout-content {
  width: 100% !important;
  padding-left: 263px;
}

.settings-table-overflow {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
}

@media screen and (max-width: 768px) {
  .config-layout-content {
    padding-left: 60px;
  }
}
