.patient-list {
  background: $white url("/img/bg.jpg");
  background-size: cover;
  min-height: calc(100vh - $topnav-height);

  // padding-bottom: 4rem;
  // Style for centering alert element
  display: flex;
  flex-direction: column;

  // Style for centering alert element
  .center-alert {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  // Style for centering alert element
  .center-search {
    position: absolute;
    width: 100%;
    top: 55%;
  }

  ///////
  .eha-table {
    width: 100%;

    thead,
    tbody {
      width: 100%;
    }

    thead,
    tbody,
    th,
    td {
      display: block;
    }

    thead tr,
    tbody tr {
      display: grid;
      grid-template-columns: 1fr 6rem 6rem 9rem 11rem 1fr 10rem 8rem 5.5rem;
    }

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
    }

    td:not(:last-child) {
      text-overflow: ellipsis;
    }

    td.name {
      font-weight: 600;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .eha-table {
      .last-visit,
      .patient-id {
        display: none;
      }

      thead tr,
      tbody tr {
        grid-template-columns: 2fr 6rem 6rem 9rem 10rem 5rem;
      }

      th,
      td {
        padding: 0.7rem 0.2rem 0.5rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .eha-table {
      thead {
        .status,
        .phone,
        .gender,
        .dob,
        .membership,
        .patient-id {
          display: none;
        }
      }

      thead tr {
        display: block;

        th {
          display: inline-block;
        }
      }

      tbody tr {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        padding: 1rem;
        margin-top: 8px;
        position: relative;
      }

      td {
        width: 50%;
        padding: 0;
      }

      td.name {
        font-weight: 600;
        font-size: 1.2rem;
        padding-bottom: 0.5rem;
      }

      td.gender,
      td.age,
      td.membership,
      td.status {
        width: auto;
      }

      td.gender {
        margin-left: auto;
      }

      td.age {
        &::before {
          content: ", ";
        }
      }

      td.status {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }

      td.dob,
      td.last-visit,
      td.patient-id,
      td.phone,
      td.membership {
        padding-right: 1rem;
        min-width: 300px;

        &::before {
          content: attr(title);
          display: inline-block;
          width: 7rem;
          font-family: Asap, sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
        }
      }
    }
  }
}
