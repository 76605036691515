///////
///////
/////// Bootstrap Variables

$white: #fff !default;
$gray-100: #fcfdfd;
$gray-200: #f4f8f6;
$gray-300: #e7f0f2;
$gray-400: #dbe9ef;
$gray-500: #c3d2d8;
$gray-600: #acbcbf;
$gray-700: #8d9da0;
$gray-800: #6e7f80;
$gray-900: #405352;
$black: #000 !default;
$body-color: #314342;

// scss-docs-start color-variables
$blue: #41b5fd;
$indigo: #5b74c6;
$purple: #7f8baa;
$pink: #d63384 !default;
$red: #f47d82;
$orange: #fd7e14 !default;
$yellow: #e8c815;
$green: #65e5aa;
$teal: #93a7a6;
$cyan: #0dcaf0 !default;

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $indigo;
$secondary-light: #8b9dd6;
$success: $green !default;
$info: $purple;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-400;
$dark: $gray-900 !default;
$blue-light: #f5ffff;
$body-light: $gray-700;

// theme colors
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "secondary-light": $secondary-light,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "blue-light": $blue-light,
  "body-light": $body-light,
  "white": $white,
);

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

// scss-docs-start border-radius-variables
$border-radius: 6px;
$border-radius-sm: 4px;
$border-radius-lg: 12px;
$border-radius-pill: 50rem !default;
$spacer: 1rem;
$paragraph-margin-bottom: 0.8rem;
$font-weight-bold: 600;
$enable-negative-margins: true;

// Navbar

// $navbar-padding-x:            $spacer;
// $navbar-padding-y:            $spacer;
$navbar-dark-color: $gray-600;
$navbar-dark-hover-color: $primary;
$navbar-brand-padding-y: 0;
$navbar-brand-font-size: 1rem;
$nav-link-font-size: 14px;
$link-decoration: none;

// Shadows

$box-shadow: 0 0 6px rgba($gray-900, 0.1),
  1px 1px 1px rgba($purple, 0.2),
  0 0 2px rgba($white, 0.3) inset;
$box-shadow-sm: 1px 1px 1px rgba($gray-900, 0.2);
$box-shadow-lg: 0 0 6px rgba($gray-900, 0.2),
  1px 1px 2px rgba($purple, 0.2);
$box-shadow-inset: 1px 1px 6px rgba($gray-900, 0.06) inset;

// Dropdown

$caret-spacing: 0.5em;
$dropdown-border-color: rgba($gray-500, 0.15);

// Button

$input-btn-padding-y: $spacer * 0.5;
$input-btn-padding-x: $spacer * 1.5;
$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-focus-box-shadow: $box-shadow;

// Modal

$modal-inner-padding: $spacer * 1.5;
$modal-header-padding-y: $spacer;
$modal-header-padding-x: $spacer * 1.5;

// scss-docs-start accordion-variables
$accordion-padding-y: 0.75rem;
$accordion-padding-x: 1rem;
$accordion-border-width: 0;

// Font

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
