.labtest-list {
  background: $white url("/img/bg.jpg");
  background-size: cover;
  min-height: calc(100vh - $topnav-height);
  padding-bottom: 4rem;

  .labtest-list-header {
    background: $purple;
    align-items: center;
  }

  .labtest-list-search {
    border-right: 1px solid $purple;
    color: $white;

    input {
      background: none;
      border: 0;
      color: $white;
      width: 100%;
      min-width: 10rem;

      &:focus,
      &:focus-visible,
      &:active,
      &:hover {
        background: none;
        border: 0;
        outline: 0;
      }
    }

    input::placeholder {
      color: rgba($white, 0.5);
    }
  }

  .labtest-list-filters {
    background: rgba($body-color, 0.1);
    box-shadow: $box-shadow-inset;
    border: 1px solid rgba($body-color, 0.1);
    flex: 1;
    border-radius: 2rem;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
    align-items: flex-end;

    .all-encounter-filters {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .eha-table {
    width: 100vw;

    thead,
    tbody {
      width: 100vw;
    }

    thead,
    tbody,
    th,
    td {
      display: block;
    }

    thead tr,
    tbody tr {
      display: grid;
      grid-template-columns: 1.2fr 0.8fr 1fr 0.7fr repeat(2, 1fr) 0.8fr 1.2fr 1.2fr 0.5fr 0.7fr;
    }

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
    }

    td:not(:last-child) {
      text-overflow: ellipsis;
    }

    td.laborder-id {
      font-size: 0.9rem;
    }

    td.status,
    th.status {
      text-align: right;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .eha-table {
      th,
      td {
        padding: 0.7rem 0.2rem 0.5rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .eha-table {
      thead {
        .status,
        .performer,
        .performed-by,
        .laborder-id,
        .encounter-id,
        .branch,
        .date-requested {
          display: none;
        }
      }

      thead tr {
        display: block;

        th {
          display: inline-block;
        }
      }

      tbody tr {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        padding: 1rem;
        margin-top: 8px;
        position: relative;
      }

      td {
        width: 50%;
        padding: 0;
      }

      td.test-name {
        font-weight: 600;
        font-size: 1.2rem;
        padding-bottom: 0.5rem;
        width: 100%;
      }

      td.date,
      td.status {
        width: auto;
      }

      td.status {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }

      td.laborder-id,
      td.encounter-id,
      td.requested-by,
      td.performed-by,
      td.patient,
      td.date-requested {
        padding-right: 1rem;
        min-width: 300px;

        &::before {
          content: attr(title);
          display: inline-block;
          width: 7rem;
          font-family: Asap, sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
        }
      }
    }
  }
}
