/* stylelint-disable selector-class-pattern */

/*** react date classes ****/
.nrd-date-filter {
  background: transparent;

  .react-datepicker-wrapper {
    border: none;
  }

  .react-datepicker__input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .react-datepicker__input-container .nrd-custom-date-input {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  .react-datepicker__input-container input:focus {
    background-color: transparent;
  }

  .react-datepicker__input-container input:hover {
    background-color: transparent;
  }

  .react-datepicker__input-container input {
    box-shadow: none;
    height: 40px;
    border-radius: 0;

    &::before {
      font-family: ehaclinics, sans-serif;
      display: flex;
      width: 1em;
      height: 1em;
      text-align: center;
      font-size: 1.3rem;
      content: "\e805";
    }
  }

  .react-datepicker-popper .react-datepicker {
    .react-datepicker__header {
      height: 3rem !important;
    }
  }
}

/****  React select classes  ***/

.nrd-select {
  &.nrd-select-filter {
    background-color: transparent;

    .nrd-select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .nrd-select__indicator-separator {
      background: rgba($white, 0.2);
      display: none;
    }

    .nrd-select__indicator,
    .nrd-select__indicator:hover {
      color: $primary;
    }

    .nrd-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.nrd-select-small {
    .nrd-select__control {
      min-height: 0;
    }

    .nrd-select__value-container {
      padding: 0.2rem 0 0.1rem 0.6rem;
    }

    .nrd-select__indicator-separator {
      display: none;
    }

    .nrd-select__indicator {
      padding: 0 0.5rem;
    }
  }

  .nrd-select__control {
    border: 0;
    background: 0;
    box-shadow: none;
    height: auto;
  }

  .nrd-select__value-container {
    padding: 1rem 1.2rem 0.8rem;
  }

  .nrd-select__value-container--has-value.nrd-select__value-container--is-multi {
    padding-left: 1rem;
  }

  .nrd-select__input-container {
    padding: 0;
    margin: 0;
  }

  .nrd-select__input {
    margin: 0;
  }

  .nrd-select__placeholder {
    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  .nrd-select__multi-value {
    background: $light;
  }

  .nrd-select__indicator,
  .nrd-select__indicator:hover {
    color: $primary;
  }

  .nrd-select__menu {
    margin-top: 1px;
  }

  .nrd-select__option--is-selected {
    background: $primary;
  }
}

/*** This class is used in the chat app but is placed here
to circumvent stylelint kebab-case rule since nrd-select__placeholder is gotten
directly form react select and the __ cannot be changed ****/
.select-user-input {
  .nrdform {
    background-color: rgb(219 233 239 / 20%) !important;
  }

  .nrd-select__placeholder {
    color: $primary !important;
  }
}
