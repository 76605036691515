/* stylelint-disable */

.mentions {
  line-height: 22px;

  .mentions__highlighter {
    padding: 0.95rem 1.1rem 0.8rem;

    strong {
      color: $secondary;
    }
  }

  .mentions__suggestions {
    border-radius: 6px;
    box-shadow: $box-shadow;
    padding: 0.6rem 0;
    overflow-y: auto;
    max-height: 40vh;
  }

  .mentions__suggestions__item {
    padding: 0.3rem 1rem;

    &:hover {
      color: $secondary;
      background: $gray-400;
    }
  }
}
.audit-log-navbar {
  padding: 5px 20px !important;
  margin-bottom: 8px;
  height: 30px !important;
  background-color: #f6fafb;
  max-width: 200%;
}

.navbar-nav .nav-link {
  transition: color 0.3s ease-in-out;
  font-weight: bold;

  &.active {
    color: $primary !important;
    border-bottom: 3px solid $primary;
  }
}

.audit-log-bg {
  background-color: #f8fbfc !important;
  font-size: 12px;
  border: none;

  span {
    margin-left: 5px;
    font-size: 10px;
    opacity: 0.8;
  }
}
