.create-new-message {
  width: 100%;
  height: calc(100% - 72px); // height - chatboxheader height
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;

  .new-message-dropdown {
    width: 100%;
    height: 100%;

    .new-message-input-header {
      width: 100%;
      height: 65px;
      background-color: rgb(255 255 255 / 80%);
      border-bottom: 1px solid #d9e9ee;
      box-shadow: 0 4px 8px 0 rgb(64 83 82 / 8%);
      padding: 0 23px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .new-message-input {
        flex: 1;
        border: 0;
        outline: 0;

        &::placeholder {
          font-style: italic;
          font-family: Asap, sans-serif;
          color: $gray-800;
          font-size: 14px;
        }
      }
    }

    .new-message-dropdown-menu {
      border-radius: 8px;
      border-bottom: 1px solid #d9e9ee;
      background: rgb(255 255 255 / 80%);
      box-shadow: 0 4px 8px 0 rgb(64 83 82 / 8%);
      padding: 15px;
      width: 95%;
      margin: auto;
      margin-top: 5px;
      height: fit-content;
      max-height: 80%;
      overflow-y: scroll;

      .new-message-dropdown-container {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      .new-message-dropdown-item {
        display: flex;
        align-items: center;
        column-gap: 20px;
        padding: 8px 14px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #e6f1f4;
        }

        .name-intials {
          width: 28px;
          height: 28px;
          font-size: 12px;
          border-radius: 100%;
          border: 1px solid $gray-900;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          color: $dark;
        }

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          border-radius: 100%;
        }

        .new-message-dot {
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background-color: rgb(128 0 0);
          right: 0;
          bottom: 2px;
        }
      }
    }
  }
}

/***  Overlay  *****/

.newroom-main-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 2;
  cursor: pointer;
}
