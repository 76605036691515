.mca-new-message {
  color: $dark;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mca-new-message-header {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 14px;
  font-size: 15px;
  column-gap: 8px;
  border-bottom: 1px solid #eaecf0;

  input {
    flex: 1;
    background-color: transparent;
    outline: none;
    border: none;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

  .eha-close {
    color: $primary;
  }
}

.mca-new-message-result-container {
  height: 87%;
  max-height: 87%;
  overflow-y: scroll;
}

.mca-new-message-result {
  padding: 10px 7px;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.mca-new-message-item {
  border-radius: 4px;
  padding: 4px 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
}

.mca-new-message-profileimg {
  position: relative;
  height: fit-content;
  width: fit-content;

  img {
    width: 27px;
    height: 27px;
    max-width: 27px;
    max-height: 27px;
    object-fit: cover;
    border-radius: 100%;
  }

  .name-initials {
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 100%;
    border: 1px solid $gray-900;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: $dark;
  }

  .eha-circle {
    position: absolute;
    bottom: -4px;
    right: -5px;
    color: $green;
    font-size: 11px;
  }
}

.mca-new-message-name {
  font-size: 15px;
  color: $dark;
  margin-top: 4px;
}

/***  Overlay  *****/

.newroom-mini-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 2;
  cursor: pointer;
}
