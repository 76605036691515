.offcanvas-end {
  top: $topnav-height;
  width: 35vw;
  min-width: 24rem;
  border-radius: $border-radius-lg;
  box-shadow: $drop-shadow;
  margin: 0.5rem;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    width: calc(100% - 1rem);
  }
}

.backdrop-light {
  background: $body-color;
}

.message {
  .follow-up-note {
    position: relative;
  }

  .eha-check-circle {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.message + .message {
  border-top: 1px solid $light;
}
