.chatapp-settings {
  padding: 14px 17px;
  height: calc(100% - 72px);
  max-height: calc(100% - 72px);
  display: flex;
  flex-direction: row;
  background: $white;
  overflow-y: scroll;
  column-gap: 20px;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      background-color: white;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #3d445b;
      border-radius: 4px;
    }
  }

  .settings-select {
    width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .select-item {
      width: 100%;
      padding: 10px;
      background: transparent;
      border-radius: 8px;
      color: #828282;
      cursor: pointer;

      &.active {
        color: $primary;
        background-color: rgb(127 139 170 / 8%);
        cursor: default;
      }
    }
  }

  .selected-section {
    flex: 1; // remember to checkout back at this when working on responsiveness
    background-color: rgb(217 233 238 / 24%);
    border-radius: 16px;
    padding: 15px;

    // message template section
    .message-template {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .item-header {
        font-weight: 600;
        font-size: 16px;
      }
    }

    .message-template-item {
      width: 100%;
      padding: 20px;
      border-radius: 8px;
      border-bottom: 1px solid #d9e9ee;
      background: white;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .eha-delete {
        color: $danger;
      }

      .eha-edit {
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .chatapp-settings {
    padding: 14px 10px;
    flex-direction: column;
    row-gap: 20px;

    .settings-select {
      width: 100%;
      flex-direction: row;

      .select-item {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .chatapp-settings {
    padding: 10px 5px;

    .selected-section {
      padding: 10px 5px;
    }
  }
}

@supports (scrollbar-color: #3d445b white ) {
  .chatapp-settings {
    &.styled-scrollbar {
      scrollbar-color: #3d445b white;
      scrollbar-width: thin;
    }
  }
}
