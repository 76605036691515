.all-channels {
  // flex-grow: 1;
  padding: 14px 17px;
  height: calc(100% - 72px); // minus chatboxheader height
  max-height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;
  overflow-y: scroll;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      background-color: white;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #3d445b;
      border-radius: 4px;
    }
  }

  .all-channels-search-input {
    height: 55px;
    min-height: 55px;
    width: 100%;
    background: rgb(217 233 238 / 24%);
    border: 1px solid #d9e9ee;
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 0 20px;
    column-gap: 10px;
    color: #6e7f80;

    input {
      border: none;
      outline: none;
      flex: 1;
      background: transparent;

      &::placeholder {
        color: #6e7f80;
        font-family: Asap, sans-serif;
        font-size: 14px;
        font-style: italic;
      }
    }
  }

  .all-channels-list {
    border: 1px solid #d9e9ee;
    border-radius: 8px;
    background-color: white;
    width: 100%;

    // flex: 1;
    height: fit-content;
    margin-top: 30px;
    padding: 20px;

    .all-channels-list-item {
      padding: 20px 6px 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      // border-bottom: 1px solid red;
      border-bottom: 1px solid rgb(127 139 170 / 16%);

      .item-details {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
      }

      .item-details-body {
        flex-wrap: wrap;
      }

      .eha-circle {
        font-size: 5px;
        color: $gray-800;
      }

      .eha-check {
        font-size: 11px;
        color: $green;
      }

      .light-gray-text {
        color: $gray-800;
        font-size: 14px;
      }

      .joined {
        color: $green;
        font-size: 14px;
      }

      .all-channels-button {
        margin: 0;
      }

      .view-btn {
        color: $primary;
        border: 1px solid $primary;

        &:hover {
          background: #f7f9fa;
        }

        &:active {
          box-shadow: none;
        }

        &.btn-outline-info {
          &:focus {
            box-shadow: 0 0 0 0.15rem rgb(65 181 253 / 25%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .all-channels {
    .all-channels-list {
      .all-channels-list-item {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .all-channels-button {
          margin: 10px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .all-channels {
    padding: 10px 5px;
  }
}

@supports (scrollbar-color: #3d445b white) {
  .all-channels {
    &.styled-scrollbar {
      scrollbar-color: #3d445b white;
      scrollbar-width: thin;
    }
  }
}
