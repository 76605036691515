.toast {
  background: $info;
  border: 0;
  box-shadow: $box-shadow-lg;

  .toast-header {
    background: rgba($white, 0.8);
    font-size: 0.9rem;
    color: $body-color;
    padding: 0.8rem 1rem 0.6rem;
  }

  .toast-body {
    padding: 0.7rem 1rem;
    background: $white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
