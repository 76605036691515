/* stylelint-disable selector-class-pattern */
.review-summary-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

// target the direct child of the parent
.review-summary-main > div {
  box-shadow: $box-shadow;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .review-summary-main {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

// Review Assessment Styles

.review-assess-main {
  border-radius: 10px !important;
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 16%));
  overflow: hidden;
  background: $white;

  .notHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr !important;
    background-color: rgb(217 233 238 / 50%);
    padding: 2rem 1rem 1.5rem;
  }

  .notFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    background-color: rgb(217 233 238 / 50%);
  }

  .notFooterCompleted {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    background-color: $white !important;
  }

  .nurse-notHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr !important;
    background-color: rgb(217 233 238 / 50%);
    padding: 2rem 1rem 1.5rem;
  }

  .doctor-notHeader {
    display: grid;
    grid-template-columns: 1.7fr 1fr 2fr !important;
    background-color: rgb(217 233 238 / 50%);
    padding: 2rem 1rem 1.5rem;
  }

  .eha-table {
    width: 100%;
    font-family: Overpass, sans-serif;

    thead {
      color: $dark;
      background-color: $white !important;
      border-bottom: 1px dashed $primary;
    }

    thead,
    tbody {
      width: 100%;
    }

    tbody > tr {
      border-bottom: 1px solid #d9e9ee;
    }

    th,
    td {
      white-space: wrap;
      overflow: hidden;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }

    thead tr {
      padding: 1rem 0.4rem;
      display: grid;
    }

    .nurse-head-row {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr !important;
    }

    .doctor-head-row {
      display: grid;
      grid-template-columns: 1.7fr 1fr 2fr !important;
    }

    .nurse-body-row {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr !important;
    }

    .doctor-body-row {
      display: grid;
      grid-template-columns: 1.7fr 1fr 2fr !important;
    }

    .overflowTd {
      overflow: visible !important;
      width: 100% !important;
    }

    tfoot {
      tr:last-child td {
        border-top: 1.5px dashed #41b5fd;
      }
    }

    .smallLabel {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    .eha-table {
      .nurse-body-row {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 1rem 0.4rem;
      }

      .doctor-body-row {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 1rem 0.4rem;
      }

      tbody tr {
        border: none;
        border-bottom: 1px dashed $primary;
        border-radius: 0;
      }

      .smallLabel {
        display: block;
        font-style: italic;
      }

      tbody tr td {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }

      tbody td {
        padding: 0;
      }
    }
  }
}

.ar-form {
  background: rgba($light, 0.2);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($light, 0.3);
  border-radius: $border-radius-sm;
  position: relative;
  width: 100%;

  label {
    display: block;
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.94rem;
    color: $gray-800;
    margin: 0.4rem 0 0;
  }

  .form-text {
    margin: 0.2rem 0 0;
  }

  input,
  textarea {
    padding: 0 1rem;
  }

  input.sm {
    padding: 0.6rem 0.4rem 0.4rem;
  }

  select {
    padding: 1rem 1.2rem 0.8rem 1rem;
  }

  input,
  select,
  textarea {
    border: 0;
    background: none;
    width: 100%;
    border-radius: $border-radius-sm;

    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
      border: 0;
      outline: 0;
    }
  }

  .unit {
    color: $gray-700;
    padding-right: 0.5rem;
  }

  &:focus-within {
    background: rgba($light, 0.4);
    border: 1px solid rgba($light, 0.6);

    /* stylelint-disable */

    .input-wrapper::before,
    .select__placeholder {
      display: none;
    }

    /* stylelint-enable */
  }

  &:hover {
    background: rgba($light, 0.4);
  }

  &.required {
    background: rgba($primary, 0.1);

    label::after {
      content: "*";
      font-size: 1.6rem;
      position: absolute;
      margin-left: 0.2rem;
      top: -0.1rem;
    }

    &:focus-within {
      background: rgba($primary, 0.2);
      border: 1px solid $light;
    }

    &:hover {
      background: rgba($primary, 0.2);
    }
  }

  &.error {
    background: rgba($danger, 0.1);
    border: 1px solid rgba($danger, 0.15);

    &:focus-within {
      background: rgba($danger, 0.15);
      border: 1px solid rgba($danger, 0.2);
    }

    &:hover {
      background: rgba($danger, 0.15);
    }
  }
}

.ar-select {
  width: 100% !important;

  &.ar-select-filter-red {
    border-radius: 4px;
    background-color: #f47d82;
    display: block;
    width: 100%;

    .ar-select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .ar-select__indicator-separator {
      background: rgba($white, 0.2);
      display: none;
    }

    .ar-select__indicator,
    .ar-select__indicator:hover {
      color: $white;
    }

    .ar-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.ar-select-filter-grey {
    border-radius: 4px;
    background-color: #7f8baa;

    .ar-select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .ar-select__indicator-separator {
      background: rgba($white, 0.2);
      display: none;
    }

    .ar-select__indicator,
    .ar-select__indicator:hover {
      color: $white;
    }

    .ar-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.ar-select-filter-ash {
    border-radius: 4px;
    background-color: #93a7a6;

    .ar-select__value-container {
      padding: 0.5rem 0 0.4rem 1rem;
    }

    .ar-select__indicator-separator {
      background: rgba($white, 0.2);
      display: none;
    }

    .ar-select__indicator,
    .ar-select__indicator:hover {
      color: $white;
    }

    .ar-select__placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  &.ar-select-small {
    .ar-select__control {
      min-height: 0;
    }

    .ar-select__value-container {
      padding: 0.2rem 0 0.1rem 0.6rem;
    }

    .ar-select__indicator-separator {
      display: none;
    }

    .ar-select__indicator {
      padding: 0 0.5rem;
    }
  }

  .ar-select__control {
    border: 0;
    background: 0;
    box-shadow: none;
    height: auto;
  }

  .ar-select__value-container {
    padding: 1rem 1.2rem 0.8rem;
  }

  .ar-select__value-container--has-value.ar-select__value-container--is-multi {
    padding-left: 1rem;
  }

  .ar-select__input-container {
    padding: 0;
    margin: 0;
  }

  .ar-select__input {
    margin: 0;
  }

  .ar-select__placeholder {
    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: 1px;
      border-bottom: 1px solid $primary;
      position: absolute;
      left: 1.2rem;
      bottom: 1rem;
    }
  }

  .ar-select__multi-value {
    background: $light;
  }

  .ar-select__single-value {
    color: $white;
  }

  .ar-select__indicator,
  .ar-select__indicator:hover {
    color: $white;
  }

  .ar-select__menu {
    margin-top: 1px;
  }

  .ar-select__option--is-selected {
    background: $primary;
  }
}
