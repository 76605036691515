// styles for main layout
.mc-layout {
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  box-sizing: border-box !important;
  position: relative;
  padding: 0;

  // border: 1px solid red;

  .header {
    height: 55px;
    width: 100%;

    // position: sticky;
  }

  .main-content {
    width: 100%;

    //   height: 100vh;
    // max-height: 100vh;
    height: 100%;
    max-height: 100%;

    // height: calc(100% - 55px);
    // max-height: calc(100% - 55px);
    display: flex;
    justify-content: flex-start; ///

    // border: 1px solid red;
  }
}

// styles for chatbox layout
.mc-cblayout {
  // flex-grow: 1;
  width: calc(100% - 385px);
  max-width: calc(100% - 385px);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.mc-cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .mc-cblayout {
    width: calc(100% - 260px);
    max-width: calc(100% - 260px);
  }
}

/* Style for mobile view of the chatapp layout */
@media screen and (max-width: 768px) {
  .mc-layout {
    .main-content {
      justify-content: flex-end;
      position: relative;
    }

    .mc-cblayout {
      width: calc(100% - 70px);
      max-width: calc(100% - 70px);
      justify-self: flex-end;
    }
  }
}

/* Overlay styles for <= 768px */

@media screen and (max-width: 768px) {
  .chatapp-overlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 50%);
    z-index: 2;
    cursor: pointer;
  }
}
