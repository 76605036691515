.mini-chatapp-reminder {
  color: $dark;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mca-reminders-select {
  margin-top: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  padding: 0 15px;
}

.mca-reminders-area {
  flex: 1;
  width: 100%;
  margin-top: 20px;
  padding: 0 12px 0 15px;
  overflow-y: scroll;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9e9ee;
      border-radius: 4px;
    }
  }
}

.mca-reminders-items {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .new {
    padding: 5px 7px;
    background-color: #e9f2ee;
    color: $green;
    border-radius: 4px;
    font-size: 15px;
  }
}

.mca-reminders-item {
  width: 100%;
  padding: 14px 10px;
  border-radius: 8px;
  border-bottom: #d9e9ee;
  background: rgb(219 233 239 / 20%);
}

.mca-reminders-item-name {
  display: flex;
  column-gap: 5px;
  font-size: 15px;
  font-weight: 600;
}

.mca-reminders-time {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: $dark;
  margin-top: 15px;
  justify-content: space-between;

  // font-weight: 600;
  .time-item {
    display: flex;
    align-items: center;
    column-gap: 2px;
    font-weight: 600;
  }
}

.mca-reminders-details {
  display: flex;
  row-gap: 10px;
  margin-top: 15px;
}

.mca-reminder-first {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.mca-reminder-header {
  font-size: 11px;
  font-family: Asap, sans-serif;
  color: $gray-800;
  font-style: italic;

  &.mca-end {
    text-align: end;
  }
}

.mca-reminder-body {
  font-family: Overpass, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: $dark;

  &.mca-end {
    text-align: end;
  }

  .time-item {
    display: flex;
    align-items: center;
    column-gap: 2px;
    font-weight: 400;
  }
}

.mca-reminders-actions {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  margin-top: 15px;

  .reschedule {
    font-size: 15px;
    color: $primary;
  }

  .cancel {
    font-size: 15px;
    color: $danger;
  }
}
