.mc-cbheader {
  width: 100%;
  height: 72px; // same height of the sidebar header
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: rgb(255 255 255 / 80%);
  border-bottom: 1px solid #d9e9ee;

  .section-name {
    color: $dark;
    font-weight: 600;
    font-family: Overpass, sans-serif;
    font-size: 18px;
  }

  .header-icons {
    display: flex;
    align-items: center;
    column-gap: 15px;

    .header-icon {
      width: 40px;
      height: 40px;
      background-color: #f0f6fa;
      display: flex;
      cursor: pointer;
    }
  }

  .ms-image-array-number {
    display: flex;
    align-items: center;
  }

  .ms-image-array {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 120px;
    height: 60px;

    img {
      width: 36px;
      height: 36px;
      max-width: 36px;
      max-height: 36px;
      border-radius: 100%;
      top: 11px,;
    }
  }
}

@media screen and (max-width: 576px) {
  .mc-cbheader {
    padding: 0 5px;

    .section-name {
      font-size: 15px;
    }

    .header-icons {
      .header-icon {
        display: none;
      }
    }

    .ms-image-array-number {
      display: none;
    }
  }
}
