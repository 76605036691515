///////
///////
/////// EHA VARIABLES

$drop-shadow: 0 1px 4px rgba($body-color, 0.2), 0 1px 1px rgba($purple, 0.2);
$topnav-height: $spacer * 3.6;

///////

html,
body {
  padding: 0;
  margin: 0;
  font-family: Overpass, sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13.5px;
  }
}

* {
  box-sizing: border-box;
}

b {
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.min-h-100 {
  min-height: 100% !important;
}

.link:hover {
  color: $primary;
  cursor: pointer;
}

.color-primary {
  color: $primary;
}

i[class^="eha-"].fs-5,
i[class^="eha-"].fs-4 {
  line-height: 1em;
}

.small {
  line-height: 1.3em;
}

button {
  border: 0;
  background: 0;
  color: inherit;

  &:disabled {
    opacity: 0.5;
  }
}

.bg-light-20 {
  background: rgba($light, 0.2) !important;
}

.bg-light-40 {
  background: rgba($light, 0.4) !important;
}

.bg-white-40 {
  background: rgba($white, 0.4) !important;
}

.headline {
  font-size: 1.1rem;
  font-weight: 600;
}

.figure-xl {
  font-size: 1.8rem;
  font-weight: 600;
}

.badge {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  padding: 0.3rem 0.4rem 0.1rem;

  &.bg-light {
    color: $body-color;
    font-weight: 400;
  }
}

.breadcrumb-item.active {
  color: $body-color;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.2rem;
  display: inline-block;

  &::before {
    content: "\E819";
    font-family: ehaclinics, sans-serif;
    color: $body-color;
    padding-right: 0.2rem;
    font-size: 0.85em;
  }
}

.circle {
  box-shadow: $box-shadow-inset;
  background: $gray-100;
  border: 1px solid rgba($light, 0.5);
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  font-weight: 600;

  &.circle-xs {
    width: 1.6rem;
    height: 1.6rem;
  }

  &.circle-sm {
    width: auto;
    border-radius: 1em;
    padding: 0.4em;
    height: 2.2em;
    min-width: 2em;
  }
}

.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $light;
  border-radius: 100%;
}

.label {
  font-family: Asap, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 0.94rem;
}

.label-group {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: baseline;

  .label {
    justify-self: end;
    margin-bottom: 0.1em;
  }
}

.box {
  border-radius: $border-radius-lg;
  background: white;
}

.arrow-left {
  position: relative;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 10px solid $gray-200;
    position: absolute;
    left: -10px;
    top: calc(50% - 8px);
  }

  &.bg-light::before {
    border-right: 10px solid $light;
  }
}

.text-box {
  border-radius: $border-radius-sm;
  background: $gray-200;
  padding: 0.5em 0.5em 0.3em;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.text-box-alert {
  background: rgba($danger, 0.3);
}

.tag {
  border-radius: $border-radius-sm;
  border: 1px solid $light;
  background: $gray-100;
  padding: 0.2em 0.5em 0.1em;
  display: flex;
  align-items: center;
  position: relative;
}

.separated-text {
  span + span {
    border-left: 1px solid rgba($body-color, 0.3);
    padding-left: 0.3em;
    margin-left: 0.3em;
  }
}

.no-wrap-fade {
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0.5rem;
    background-image: linear-gradient(to right, transparent, $gray-100);
    right: 0;
  }
}

.icon-list {
  list-style: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: flex-start;
  }
}

.list-item {
  border-radius: $border-radius-lg;
  background: $white;
  box-shadow: $box-shadow;
  margin-bottom: $spacer * 0.75;
  transition: 0.4s box-shadow;

  &:not(.non-clickable):hover {
    outline: 2px solid rgba($secondary-light, 0.3);
    cursor: pointer;
  }

  .label {
    color: $gray-800;
  }
}

.border-notfirst-top:not(:first-child) {
  border-top: 1px solid $light;
}

.border-notlast-bottom:not(:last-child) {
  border-bottom: 1px solid $light;
}

.main-container {
  height: calc(100vh - $topnav-height);
  background: $white;
}

.view-fullscreen {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: $border-radius-lg;

  .close-fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.main-bg-container {
  min-height: calc(100vh - $topnav-height);
  background: $white url("/img/bg.jpg");
  background-size: cover;

  &.fullscreen {
    min-height: 100vh;
  }
}

.age {
  .months {
    font-size: 0.8em;
    margin-left: 0.1em;
  }
}

.filter-toggle {
  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
    margin: 0;
    padding-right: 34px;
    position: relative;
    color: $gray-800;
    width: 100%;

    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 1px;
      width: 26px;
      height: 16px;
      background-color: rgba($body-color, 0.2);
      box-shadow: 0 0 2px rgba($body-color, 0.2) inset;
      border-radius: 12px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      right: 1px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $white;
      transition: all 0.2s;
    }
  }

  input[type="checkbox"]:checked + label {
    color: $body-color;

    &::before {
      background: $primary;
    }

    &::after {
      background: $white;
      right: 11px;
    }
  }
}

/* stylelint-disable */

.list-checkbox {
  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
  }

  input[type='checkbox'] + label {
    cursor: pointer;
    margin: 0;
    padding-right: 4rem;
    position: relative;
    width: 100%;

    .billing-item {
      opacity: 0.5;

      .icon {
        color: $gray-800;
      }

      .icon::before {
        content: '\e807';
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 3px;
      width: 1.5rem;
      height: 1.5rem;
      background-color: rgba($body-color, 0.1);
      border: 1px solid $primary;
      border-radius: 4px;
    }

    &::after {
      content: '\e84d';
      font-family: ehaclinics, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.2rem;
      color: $white;
      position: absolute;
      bottom: 1px;
      right: 2px;
      display: none;
    }
  }

  input[type='checkbox']:checked + label {
    .billing-item {
      opacity: 1;

      .icon {
        color: $success;
      }

      .icon::before {
        content: '\e84d';
      }
    }

    &::before {
      background: $primary;
      box-shadow: $box-shadow;
    }

    &::after {
      display: block;
    }
  }
}

/* stylelint-enable */

.table-list {
  background: $white url("/img/bg.jpg");
  background-size: cover;
  min-height: calc(100vh - $topnav-height);
  padding-bottom: 4rem;

  .eha-table {
    @include media-breakpoint-up(sm) {
      td {
        border-bottom: 2px solid transparent;
        background: $white;

        &:first-child {
          border-left: 0.5rem solid transparent;
        }

        &:last-child {
          border-right: 0.5rem solid transparent;
        }
      }

      th {
        &:first-child {
          border-left: 0.5rem solid $purple;
        }

        &:last-child {
          border-right: 0.5rem solid $purple;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      thead,
      tbody {
        padding: 0;
      }

      table,
      thead,
      tbody,
      th,
      td {
        display: block;
      }

      tbody tr {
        display: block;
        width: calc(100% - 1rem);
        margin: 0.5rem;
        background: $white;
        padding: 1rem;
        position: relative;
      }

      tbody td {
        background: none;
        border-left: none;
        padding: 0;
        padding-right: 1rem;

        + td:not(.status)::before {
          content: attr(title);
          display: inline-block;
          width: 7rem;
          font-family: Asap, sans-serif;
          font-weight: 400;
          font-style: italic;
          font-size: 13px;
        }
      }

      th + th {
        display: none;
      }

      td.name {
        font-weight: 600;
        font-size: 1.2rem;
        padding-bottom: 0.5rem;
        width: 100%;
      }

      td.status {
        position: absolute;
        top: 1rem;
        right: 0;
      }
    }
  }
}

.table-list-header {
  background: $purple;
  align-items: center;
}

.table-list-search {
  color: $white;

  input {
    background: none;
    border: 0;
    color: $white;
    width: 100%;
    min-width: 10rem;

    &:focus,
    &:focus-visible,
    &:active,
    &:hover {
      background: none;
      border: 0;
      outline: 0;
    }
  }

  input::placeholder {
    color: rgba($white, 0.5);
  }
}

.table-list-filters {
  background: rgba($body-color, 0.1);
  box-shadow: $box-shadow-inset;
  border: 1px solid rgba($body-color, 0.1);
  flex: 1;
  border-radius: 2rem;
}

.merge-header {
  color: #e5ebeb;
}

.merge-form-container {
  background-color: #8a93ad;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  height: 48px;
}

.merge-form-label {
  color: #fff;
  margin-right: 8px;
  margin-bottom: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 14px;
}

.merge-form-controller-container {
  border-left: 1px solid #99b0af;
  padding-left: 8px;
  display: flex;
  flex: 1;
}

.merge-form-control {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
}

.merge-form-footer-container {
  bottom: 0;
  background-color: #f0f9ff;
  border-top: 1px solid #dee2e6;
  width: 100%;
}

.destination-text-container {
  display: flex;
  padding: 0.5rem 2rem 0.25rem;
  justify-content: flex-end;

  strong {
    color: #6c757d;
    font-size: 1rem;
  }
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0.25rem 2rem 1rem;
  border-bottom: 1px solid #a5cbd7;
  justify-content: flex-end;

  .tags-name {
    border: 1.5px solid #5a7574;
    border-radius: 1rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.9rem;
    color: #5a7574;
  }

  .tag-count {
    color: #415ebc;
  }
}

.merge-form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding-top: 10px;
  column-gap: 10px;
  border-radius: 50%;
  width: 10rem;
}

.button-container {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
}

.merge-ui-badge {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  border-radius: 50px;
  width: fit-content;

  &.unmerged {
    background: #99b0af;
  }

  &.merged {
    background: #63cd92;
  }
}

/* stylelint-disable */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: 1px solid;
    }
    .border#{$infix}-right {
      border-right: 1px solid;
    }
    .border#{$infix}-bottom {
      border-bottom: 1px solid;
    }
    .border#{$infix}-left {
      border-left: 1px solid;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: 1px solid;
      border-right: 1px solid;
    }

    .border#{$infix}-y {
      border-top: 1px solid;
      border-bottom: 1px solid;
    }
  }
}

.addendum{
  background: $white;
  border-radius: $border-radius-lg;
}
/* stylelint-enable */

.outline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background-color: white;

  &.btn-outline-primary {
    background-color: white;
  }

  &.btn-outline-primary:disabled {
    background-color: white;
  }
}

.lab-download-text {
  font-size: 13.3px;
  margin-top: 2px;
}
