.messages-area {
  height: calc(100% - 72px); // minus chatboxheader height
  max-height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;
}

// Styling for where messages get displayed
.ma-messages {
  flex: 1;
  overflow-y: scroll;

  // maxheight will be the height of the chatbox layout  - height of messages input comp
  &.styled-scrollbar {
    &::-webkit-scrollbar {
      background-color: white;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #3d445b;
      border-radius: 4px;
    }
  }
}

.ma-messages-body {
  padding: 10px 20px;
  word-break: break-all;
}

.ms-dash {
  height: 0.1px !important;
  border: 1px solid #dbe9ef;
}

.ms-new-entrant {
  margin: auto;
  font-size: 12px;
  color: $gray-800;
  width: fit-content;
}

.message-content-body {
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  position: relative;

  &:hover {
    background-color: #e4f1f5;
    border-radius: 4px;
  }
}

.message-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  .eha-dots {
    transform: rotate(90deg) !important;
  }
}

.messaging-delete-popup {
  position: absolute;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: white;
  box-shadow: $box-shadow-sm;
  color: $danger;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: -10px;
}

.ms-message-stream {
  width: 100%;
  display: flex;
  flex-direction: column;

  .ms-message-stream-date {
    font-size: 12px;
    color: $gray-800;
  }

  .message-profilepic {
    width: 30px;
    height: 30px;
    aspect-ratio: 1/1;
    border-radius: 100%;
    object-fit: cover;
  }

  .message-content-name {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  .main-name-initials {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    font-size: 12px;
    border-radius: 100%;
    border: 1px solid $gray-900;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: $gray-900;
  }

  .message-sender {
    font-size: 15px;
    font-weight: 600;
    margin-top: 2px;
  }

  .text-message {
    line-height: 28px;
    letter-spacing: 0.026px;
    word-break: break-all;
  }

  .message-image {
    width: 320px;
    height: 250px;
    border-radius: 12px;
    margin-top: 10px;
    object-fit: cover;
  }
}

.mc-message-input-container {
  // padding will determine the height of all children elements
  width: 100%;
  padding: 10px 20px 20px; /// Verp important. refer to this for padding of the message area component
  max-height: 200px;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mc-message-input {
    width: 100%;
    padding: 6px 5px;
    display: flex;
    align-items: center;
    background-color: rgb(217 233 238 / 20%);
    box-shadow: 1px 1px 6px 0 rgb(64 83 82 / 6.8%) inset;
    border: 1px solid  rgb(110 127 128 / 20%);
    border-radius: 8px;
  }

  .mc-message-textarea-container {
    display: flex;
    justify-self: flex-end;
    position: relative;
    flex: 1;
    background-color: transparent;
    border-left: 1px solid  rgb(110 127 128 / 20%);
    padding-left: 12px;
  }

  .mc-message-textarea {
    width: 100%;
    resize: none;
    height: 55px;
    max-height: 55px;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      border: none;
      outline: none;
    }

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      margin-top: 20px;
    }
  }

  .mc-attachment {
    width: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mc-message-send-button {
    width: 90px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .send-button {
    // width: 90px;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
    padding: 1px 4px;
    background-color: #7f8baa;
    color: white;
    border-radius: 8px;
    cursor: pointer;

    &:active {
      background-color: #5b74c6;
    }

    div {
      display: block;
    }

    img {
      height: 25px;
    }
  }

  .mc-message-emoji {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// *****Join channel styles***** //
.join-channel {
  width: 100%;
  padding: 10px 20px; // Very important. refer to this for padding of the message area component
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// *****Templates List styles ******* //
.templates-list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 8px;

  .template-card {
    width: 100%;
    padding: 17px;
    border-radius: 8px;
    border: 1px solid #d9e9ee;
    background: rgb(255 255 255 / 60%);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 14px;

    h5 {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 576px) {
  .ma-messages-body {
    padding: 10px 5px;
  }

  .ms-message-stream {
    .message-profilepic {
      width: 23px;
      height: 23px;
    }

    .message-image {
      width: 260px;
      height: 200px;
    }
  }

  .mc-message-input-container {
    padding: 8px 5px;

    .mc-message-input {
      align-items: flex-end;
    }

    .mc-message-emoji {
      width: 30px;
      height: 30px;
    }

    .mc-message-textarea-container {
      padding-left: 4px;
    }

    .mc-attachment {
      width: 40px;
    }

    .mc-message-send-button {
      width: 30px;
      height: 70%;
    }

    .send-button {
      &:active {
        background-color: #5b74c6;
      }

      div {
        display: none;
      }

      img {
        height: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .templates-list {
    grid-template-columns: 1fr;
  }
}

// *****Templates Page styles ******* //
.template-page-item {
  padding-bottom: 15px;
  border-bottom: 1px solid #d9e9ee;
}

@supports (scrollbar-color: #3d445b white) {
  .ma-messages {
    &.styled-scrollbar {
      scrollbar-color: #3d445b white;
      scrollbar-width: thin;
    }
  }
}
