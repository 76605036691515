/***
SIDEBAR
***/
.config-sidebar {
  position: fixed;
  width: 263px;
  height: calc(100vh - $topnav-height);
  display: block;
  z-index: 10;
  background-color: #f7fbfc;
}

.config-sidebar-content {
  min-height: 100%;
  max-height: 100%;
  padding-top: 40px;
  overflow-y: scroll;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(#d9e9ee, 0.16);
    }

    &::-webkit-scrollbar-thumb {
      background: #7b9493;
      border-radius: 4px;
    }
  }
}

.sidebar-toggle {
  display: none;
}

.config-sidebar-search {
  padding: 0 15px 20px;
  display: block;
}

.config-sidebar-search-box {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  background: rgba($light, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;

  input {
    outline: none;
    border: none;
    background: transparent;
    flex: 1;
    width: 80%;

    &::placeholder {
      color: $dark;
      margin-top: 3px;
    }
  }

  .eha-search {
    color: $primary;
    font-size: 15px;
    margin-top: 5px;
  }
}

.config-sidebar-accordion {
  width: 100%;

  &:nth-last-child(1) {
    border-bottom: 1px solid $gray-400;
  }
}

.config-sidebar-accordion-parent {
  padding: 20px 15px;
  border-top: 1px solid $gray-400;
  width: 100%;

  ul {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  &.collapse {
    padding: 15px;
  }

  .eha-care-plan {
    font-size: 0.9rem;
  }
}

.config-child-accordion {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.config-list {
  color: $dark;
  cursor: pointer;
  padding: 9px 5px;
  border-radius: 4px;

  a {
    color: $dark;
  }

  &.selected {
    background: rgba($light, 0.8);
  }

  &:hover {
    background-color: #f1e7e7;
  }
}

.config-cat,
.config-child-cat {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.config-cat-mobile-icon {
  display: none;
}

.config-child-cat {
  font-weight: 400;

  .eha-up {
    color: $primary;
  }

  .eha-down {
    color: $primary;
  }
}

.config-child-cat-items {
  margin-top: 3px !important;

  div {
    padding: 3px 14px;
    font-size: 14px;
    border-radius: 3px;
    color: $dark;

    a {
      color: $dark;
      display: block;
    }

    &.selected {
      background: rgba($light, 0.8);
    }

    &:hover {
      background-color: #f1e7e7;
    }
  }
}

.config-child-cat-items-link {
  width: 100%;
}

.config-no-cat-item {
  width: 100%;

  a {
    display: block;
  }
}

// Terminology styles
.term {
  color: $dark;
  cursor: pointer;
  padding: 9px 5px;
  border-radius: 4px;
}

.term-header {
  padding-bottom: 9px;
}

.term-search {
  width: 100%;
  height: 38px;
  outline: none;
  border-radius: 7px;
  padding: 1px 10px;
  border: 1px solid $body-light;

  &:focus {
    outline: none;
    border: 1px solid $body-light;
  }
}

.term-list {
  padding: 4px 0 4px 5px;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.term-item {
  width: 100%;
  word-break: break-all;
  padding: 3px 4px;
  border-radius: 5px;

  a {
    color: $dark;
  }

  &:hover {
    background-color: #f1e7e7;
  }
}

@media screen and (max-width: 768px) {
  .config-sidebar {
    &.expanded {
      width: 263px;
    }

    &.collapsed {
      width: 60px;
    }
  }

  .sidebar-toggle {
    position: absolute;
    z-index: 20;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background-color: rgba(#d9e9ee, 1);
    justify-content: center;
    right: -7px;
    top: 85px;

    .eha-left,
    .eha-right {
      margin-top: 2px;
    }
  }

  .config-child-accordion {
    &.collapsed {
      display: none;
    }

    &.expanded {
      display: block;
    }
  }

  .config-cat {
    &.expanded {
      display: flex;
    }

    &.collapsed {
      display: none;
    }
  }

  .config-cat-mobile-icon {
    &.expanded {
      display: none;
    }

    &.collapsed {
      display: block;
    }
  }

  .config-sidebar-search {
    &.expanded {
      display: block;
    }

    &.collapsed {
      display: none;
    }
  }
}
