.mca-room {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.mca-message-area {
  flex: 1;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &.styled-scrollbar {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9e9ee;
      border-radius: 4px;
    }
  }
}

.mca-input-area {
  height: 100px;
  max-height: 140px;
  padding: 13px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eaecf0;
  background: linear-gradient(to right, #f0f6fa, #f0f6fa), url("/img/bg.jpg") 0 0 / 100% 100% no-repeat;
}

.mca-input-box {
  border-radius: 8px;
  width: 100%;
  border: 1px solid rgb(110 127 128 / 20%);
  background: rgb(217 233 238 / 20%);
  box-shadow: 1px 1px 6px 0 rgb(64 83 82 / 6.8%) inset;
  display: flex;
  align-items: center;
  padding: 8px 5px;
}

.mca-message-emoji {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
  }
}

.mca-message-textarea-container {
  flex: 1;
  border-left: 1px solid rgb(110 127 128 / 20%);
  padding: 0 5px;

  .mca-message-textarea {
    height: 48px;
    width: 100%;
    max-height: 48px;
    outline: none;
    border: none;
    background-color: transparent;
    resize: none;
    font-size: 14px;

    &:focus {
      outline: none;
      border: none;
    }

    &::placeholder {
      margin-top: 20px !important;
      color: $purple;
      opacity: 0.4;
      font-size: 14px;
    }
  }
}

.mca-attachment {
  img {
    width: 30px;
    height: 30px;
  }
}

.mca-message-send-button {
  .send-button {
    width: 30px;
    height: 30px;
    border: 1px solid $purple;
    border-radius: 4px;
    background: $purple;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);

    &:active {
      background-color: #5b74c6;
    }

    img {
      transform: rotate(-45deg);
      margin-top: -3px;
      margin-left: 3px;
    }
  }
}

.mca-message-area-container {
  padding: 10px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.mca-message-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.mca-message-image {
  position: relative;
  width: 100%;
  display: flex;
  column-gap: 10px;
  font-family: Overpass, sans-serif;
  padding: 7px;

  .mca-profile {
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 100%;
    border: 1px solid $gray-900;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: $dark;
  }

  &:hover {
    background-color: #e4f1f5;
    border-radius: 4px;
  }
}

.mca-message-more {
  display: flex;
  position: absolute;
  width: fit-content !important;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg) !important;
  right: 7px;
  top: 27px;

  &:hover {
    cursor: pointer;
  }
}

.mca-message-delete-popup {
  position: absolute;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: white;
  box-shadow: $box-shadow-lg;
  color: $danger;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: -10px;
}

.mca-message-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
}

.mca-message-box-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.mca-message-name {
  font-size: 15px;
  color: black;
  font-weight: 600;
  font-family: Overpass, sans-serif;
}

.mca-message-time {
  color: #667085;
  font-size: 12px;
}

.mca-text {
  margin-top: 10px;
  color: $dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.024px;
}

.mca-file {
  width: 256px;
  max-width: 256px;
  border-radius: 8px;
  border: 1px solid $primary;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
  column-gap: 7px;

  img {
    margin-top: -1px;
  }
}

.mca-filename-size {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.mca-filename {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $dark;
  font-family: Overpass, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.mca-size {
  font-size: 13px;

  // font-family: Asap;
  color: #667085;
  font-style: normal;
}

.mca-message-divider {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.mca-message-line {
  flex: 1;
  border: 1px solid #eaecf0;
  height: 1px;
}

.mca-message-date {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: center;
  color: #667085;
}
