.dropdown.channels-action-menu {
  background: rgb(255 255 255 / 80%) !important;
  border: 0;

  .dropdown-toggle {
    background: rgb(255 255 255 / 80%) !important;
    border: 0;
    padding: 0;
    box-shadow: none;

    &.btn-primary {
      box-shadow: none;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 10px 15px;
  }

  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    padding: 10px !important;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px !important;
    padding: 6px 10px;
    border-radius: 7px;
    min-width: 160px;

    &.danger {
      color: $danger;

      &:hover {
        background-color: rgb(244 125 130 / 20%);
      }
    }
  }
}
