.modal-min-90 {
  @media screen and (min-width: 576px) and (max-width: 992px) {
    max-width: 800px;
    width: 90vw;
  }
}

.modal-fullscreen.fullscreen-margin {
  max-width: none;
  height: calc(100% - 3.5rem);
  width: 90vw;
  margin: 1.75rem auto;

  .modal-content {
    border-radius: 12px;
  }

  @include media-breakpoint-down(md) {
    width: calc(100vw - 1rem);
    height: calc(100% - 1rem);
    margin: 0.5rem;
  }
}

.modal.show + .modal-backdrop {
  z-index: 1055;
  opacity: 0.2;
}

.modal-sidebar {
  background: white;
  box-shadow: $box-shadow-lg;
  overflow-y: auto;
  z-index: 1;
}

.modal-sidebar-content {
  height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: 0;

  @include media-breakpoint-down(lg) {
    height: auto;
    overflow: auto;
  }
}

.modal-tabs {
  position: sticky;
  top: -1.5rem;

  .modal-tab {
    font-weight: 600;
  }
}

.flex-basis-28 {
  flex-basis: 28%;
}

.flex-basis-72 {
  flex-basis: 72%;
}
