.terminology-list {
  background: $white url("/img/bg.jpg");
  height: 100%;
  background-size: cover;
  min-height: calc(100vh - $topnav-height);
  padding-bottom: 4rem;

  .eha-table {
    thead,
    tbody,
    th,
    td {
      display: block;
    }

    thead tr,
    tbody tr {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    th,
    td {
      white-space: nowrap;
      overflow: hidden;
    }

    td:not(:last-child) {
      text-overflow: ellipsis;
    }

    td.laborder-id {
      font-size: 0.9rem;
    }

    td.status,
    th.status {
      text-align: right;
    }
  }
}

.term-table {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  padding: 15px 0;
}

.term-header-row {
  background-color: #e4e2e2;
  padding: 4px 0;

  div {
    display: flex;
    align-items: center;
  }

  input {
    outline: none;
    border: none;
  }
}

.term-body-row {
  display: grid;
  align-items: center;
  gap: 1px;
  margin-bottom: 2px;
  padding: 0 15px;

  &.odd {
    background: rgba($light, 0.8);
  }

  input {
    background-color: transparent;
    outline: none;
    padding: 4px 3px;
    border: 0.2px solid $body-light;
  }
}

.terminology-body {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: relative;
}

.terminology-section {
  width: 100%;
  min-height: calc(100vh - 230px);
  position: relative;
}

.term-loading-spinner {
  position: absolute;
  top: 48%;
  left: 48%;
}
