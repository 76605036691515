.btn-primary {
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  box-shadow: $box-shadow;
  padding-bottom: $spacer * 0.4;

  &:focus,
  &:hover,
  &:active {
    color: $white;
  }

  &.pill {
    border-radius: 50rem;
  }

  &.btn-sm {
    padding-bottom: 0.16em;
  }

  &:disabled {
    color: $white;
    background: $gray-600;
    border-color: $gray-600;
  }

  &.loading {
    color: $gray-500;
    background: $gray-100;
    border-color: $light;
    box-shadow: none;

    .loading-dots {
      position: relative;
      margin-right: 0.6rem;
      margin-left: -0.3rem;
      top: 0.15rem;
    }
  }
}

.btn-white {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: $primary;

  &:focus,
  &:hover,
  &:active {
    /* stylelint-disable */
    color: darken($primary, 20);
    /* stylelint-enable */
  }
}

.btn-danger,
.btn-warning,
.btn-success,
.btn-secondary,
.btn-info,
.btn-light,
.btn-cancel {
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  box-shadow: $box-shadow;
  padding-bottom: $spacer * 0.4;

  &:focus,
  &:hover,
  &:active {
    color: $white;
  }

  &.pill {
    border-radius: 50rem;
  }

  &.btn-sm {
    padding-bottom: 0.16em;
  }

  &:disabled {
    color: $white;
    background: $gray-600;
    border-color: $gray-600;
  }

  &.loading {
    color: $gray-500;
    background: $gray-100;
    border-color: $light;
    box-shadow: none;

    .loading-dots {
      position: relative;
      margin-right: 0.6rem;
      margin-left: -0.3rem;
      top: 0.15rem;
    }
  }
}

/* stylelint-disable */

.btn-light {
  color: $body-color;

  &:focus,
  &:hover,
  &:active {
    color: $primary;
  }
}

.btn-cancel {
  background: $gray-600;

  &:focus,
  &:hover,
  &:active {
    background: $gray-500;
  }
}

.btn-link {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  padding: 0;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    color: lighten($primary, 10);
  }
}
/* stylelint-enable */
