.print-only {
  display: none;
}

.print-only-prescription-title {
  display: none;
}

@media print {
  @page {
    margin: 1cm 0;
  }

  @page :first {
    margin: 0;
  }

  html,
  body {
    font-size: 12px;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: block;
  }

  .prescription-report-section {
    position: relative;
    height: 100vh;
  }

  .print-only-prescription-title {
    display: flex;
    width: 85%;
  }

  .patient-info {
    margin-top: 40px;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1.7fr 1fr 1.7fr;
    row-gap: 7px;
  }

  .grid-label {
    font-family: Asap, sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    color: #6e7f80;
  }

  .grid-value {
    font-size: 1.1rem;

    &.bold-title {
      font-weight: 600;
    }
  }

  .blue-divider {
    width: 100%;
    height: 15px;
    margin-bottom: 25px;
    border-bottom: 1.2px dashed $primary;
  }

  .report-signature {
    margin-top: 30px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .prescription-table {
    width: 100%;

    thead,
    tbody {
      width: 100%;
    }

    thead {
      background: rgba($light, 0.4);
    }

    th,
    td {
      padding: 0.7rem 0.8rem 0.5rem;
      border-bottom: 1px solid $light;
    }

    th {
      font-family: Overpass, sans-serif;
      font-weight: 600;
      font-size: 1.2rem;
      font-style: normal !important;
    }
  }

  .printable {
    background: $white;
  }

  .watermark {
    margin-top: 45%;
    position: absolute;
    z-index: 1;
    transform: rotate(-45deg);
    color: #73948e;
    font-size: 150px;
    opacity: 0.2;
  }

  .print-w-100 {
    width: 100%;
  }

  .no-page-break {
    page-break-inside: avoid;
  }

  .page-break {
    page-break-after: always;
  }

  .patient-record-container {
    padding: 0;
    background: none;

    .tabbar,
    .header,
    .side-nav {
      display: none;
    }
  }

  .modal-open {
    .patient-record-container {
      display: none;
    }
  }

  .modal {
    position: relative;
  }

  .modal-backdrop,
  .modal-header,
  .modal-footer {
    display: none;
  }

  .modal-content {
    border: none;
    background: none;
  }

  .modal-dialog {
    margin: 0;
    max-width: none;
    align-items: flex-start;
  }

  .modal-body {
    padding: 0;
  }

  .dashboard-card,
  .list-item {
    border: 1px solid $light;
    box-shadow: none;
    border-radius: 4px;
  }

  .card-widget.small-widget {
    padding: 0.2rem 0.6rem;

    i {
      font-size: 1rem;
    }

    .figure {
      font-size: 0.9rem;
    }
  }

  .table-light {
    font-size: 0.8rem;
  }

  .prescription-report {
    font-family: Overpass, sans-serif;
    padding: 40px;
    min-height: 100vh;
    background: white;
    font-size: 14px !important;
    color: $gray-900;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .prescription-top {
      width: 100%;
    }

    .title {
      font-size: 23px;
      font-weight: 600;
      color: #405352;
    }

    .prescription-img-section {
      margin-bottom: 30px;

      img {
        width: 220px;
      }

      .clinic-address {
        margin-left: 60px;
        margin-top: 10px;
      }
    }

    .report-date {
      position: absolute;
      top: 0;
      right: 71px;
    }

    .patient-info {
      margin-top: 40px;
      width: 85%;
      min-height: 40px;
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr 1.5fr;
      row-gap: 10px;
    }

    .grid-label {
      font-family: Asap, sans-serif;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
    }

    .grid-value {
      font-size: 15px;
      font-weight: 600;
    }

    .blue-divider {
      width: 100%;
      min-height: 30px;
      border-bottom: 1.5px dotted $primary;
    }

    .medications {
      width: 100%;
      font-size: 18px;
      margin-top: 20px;
    }

    .medications-header {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 0.8fr;
      font-weight: 600;
      padding: 7px 12px;
      background-color: rgb(248 248 248) !important;
    }

    .medications-row {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 0.8fr;
      padding: 10px 12px;
      border-bottom: 1px solid $gray-400;
    }

    .prescription-bottom {
      margin-top: 50px;
    }
  }
}

.show-print {
  display: block;
  padding: 3rem;

  &.printable {
    background: $white;
  }

  &.sp-letter {
    padding: 2em;
  }

  .sp-letter-title {
    font-family: Overpass, sans-serif;
    font-weight: 600;
    font-size: 1.7rem;
  }

  .sp-referral-logo {
    width: 200px;
    position: relative;
    margin-bottom: 0.6em;

    img {
      width: 100%;
    }
  }

  .sp-value-row {
    margin: 0.25em 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
    align-items: baseline;

    .label {
      color: #6e7f80;
      font-family: Asap, sans-serif;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }

  .sp-top-border {
    border-top: 1px dashed $primary;
  }

  .sp-sections {
    margin-top: 30px;
  }

  .sp-appended {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 15px;
      font-weight: 600;
      font-family: Overpass, sans-serif;
      margin-top: 30px;
    }

    p,
    ul,
    li,
    ol {
      font-size: 14px;
      font-family: Overpass, sans-serif;
      max-width: 100%;
      word-break: break-all;
    }
  }

  .sp-sign {
    margin-top: 100px;
  }
}

.stamp-con {
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .stamp-date {
    position: absolute;
    top: 31%;
    left: 28%;
    font-size: 11px;
  }

  .stamp-location {
    position: absolute;
    top: 53%;
    left: 33%;
    font-size: 10px;
    max-width: 50%;
    word-break: break-all;
  }
}
