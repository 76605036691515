.selected-section-table {
  .eha-table {
    width: 100%;
    font-family: Overpass, sans-serif;
    background: transparent !important;

    thead,
    tbody {
      width: 100%;
    }

    tr {
      padding: 0 5px;
    }

    thead {
      background: transparent !important;
      box-shadow: none;

      tr {
        display: grid;
        background-color: #7f8baa;
        grid-template-columns: 0.9fr 1.8fr 0.5fr 0.7fr !important;
        border-radius: 8px !important;
      }
    }

    th,
    td {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }

    th > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .settings-body {
      width: 100%;
      background: transparent !important;

      // border: 1px solid red;
      margin-top: 10px !important;
      display: flex;
      flex-direction: column;
      row-gap: 7px;

      tr {
        display: grid;
        grid-template-columns: 0.9fr 1.8fr 0.5fr 0.7fr !important;
        border-radius: 8px;

        &:hover {
          background-color: #d9e9ee;
          cursor: pointer;
        }
      }

      .not-colored {
        background-color: transparent;
      }

      .status {
        text-transform: capitalize;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 4px;

        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .two-cell-deck {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
      }

      .show-hover-buttons {
        position: relative;

        .two-cell-deck {
          position: relative;
          width: 100%;

          .access-hover-buttons {
            position: absolute;
            display: flex;
            align-items: center;
            column-gap: 4px;
            right: 10px;
            top: 4px;
            font-size: 12px;

            .eha-edit {
              color: $primary;
            }

            .eha-delete {
              color: $danger;
            }
          }
        }

        .pending-dot {
          min-width: 8px;
          min-height: 8px;
          border-radius: 100%;
          padding: 0 !important;
          background-color: #c9ac0d;
          display: block;
          margin-top: -3px;
        }
      }

      .remove-hover-buttons {
        position: relative;

        .two-cell-deck {
          position: relative;

          .access-hover-buttons {
            display: none;
          }

          .pending-dot {
            display: none !important;
          }
        }
      }

      .cell-first {
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .access-list {
        color: $info;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cell-second {
        color: $info;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cell-date {
        font-size: 13px;
      }
    }

    td > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 992px) {
  .selected-section-table {
    .eha-table {
      tr {
        padding: 0;
      }

      thead {
        tr {
          grid-template-columns: 0.6fr 0.6fr 1fr 1fr !important;

          th {
            font-size: 13px !important;

            // border: 1px solid red;
            padding-left: 4px;
            padding-right: 2px;
          }
        }
      }

      .settings-body {
        tr {
          display: flex;
          flex-direction: column;
        }

        .access-list {
          word-break: break-all;
          white-space: wrap;
          overflow: visible;
        }
      }
    }
  }
}
