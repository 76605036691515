.all-channels-select {
  // width: 240px;
  .chat-app-custom-select {
    width: 100%;
    position: relative;

    .custom-select-input {
      width: 100%;
      height: 100%;
      border: 0.5px solid #6e7f80;
      border-radius: 8px;
      display: flex;
      flex-direction: row !important;
      align-items: center;
      column-gap: 10px;
      font-size: 14px;
      padding: 4px 7px;

      .selected-value {
        // border: 1px solid red;
        background: transparent;

        // flex: 1;
        width: fit-content;
        padding: 0 4px;
      }

      .eha-down {
        cursor: pointer;
      }
    }

    .custom-select-dropdown {
      // width: 100%;
      width: fit-content;
      position: absolute;
      z-index: 10;
      padding: 10px 15px;
      background-color: white;
      box-shadow: $box-shadow;
      border-radius: 5px;
      top: 35px;

      .custom-select-item {
        padding: 4px 6px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background: #d9e9ee;
        }
      }
    }
  }
}

// .chat-app-custom-select {
//   width: 100%;
//   position: relative;

//   .custom-select-input {
//     width: 100%;
//     height: 100%;
//     border: 0.5px solid #6e7f80;
//     border-radius: 8px;
//     display: flex;
//     flex-direction: row !important;
//     align-items: center;
//     column-gap: 10px;
//     font-size: 14px;
//     padding: 4px 7px;

//     .selected-value {
//       // border: 1px solid red;
//       background: transparent;

//       // flex: 1;
//       width: fit-content;
//       padding: 0 4px;
//     }

//     .eha-down {
//       cursor: pointer;
//     }
//   }

//   .custom-select-dropdown {
//     // width: 100%;
//     width: fit-content;
//     position: absolute;
//     z-index: 10;
//     padding: 10px 15px;
//     background-color: white;
//     box-shadow: $box-shadow;
//     border-radius: 5px;
//     top: 35px;

//     .custom-select-item {
//       padding: 4px 6px;
//       border-radius: 5px;
//       cursor: pointer;

//       &:hover {
//         background: #d9e9ee;
//       }
//     }
//   }
// }
