.filter-select {
  display: flex;
  position: relative;
  align-items: baseline;

  label {
    flex: 1;
    display: flex;
    background: $gray-700;
    border-radius: $border-radius;
    margin-bottom: 0.1rem;
    padding: 0.3rem 0.6rem 0.2rem 1.9rem;
    opacity: 0.7;
    cursor: pointer;
    letter-spacing: -0.01em;
    font-size: 0.9rem;

    &:hover {
      opacity: 1;
    }
  }

  .checkmark {
    position: absolute;
    background: $gray-700;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    left: 0.2rem;
    top: 0.3rem;
    color: $white;
    font-size: 1rem;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    border: 2px solid $gray-700;
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="checkbox"]:not(:checked) ~ label,
  input[type="radio"]:not(:checked) ~ label {
    background: $white !important;
  }

  input[type="checkbox"]:not(:checked) ~ .checkmark,
  input[type="radio"]:not(:checked) ~ .checkmark {
    i {
      display: none;
    }
  }

  input[type="radio"]:not(:checked) ~ .checkmark {
    background: none !important;
  }

  input[type="checkbox"]:checked ~ label,
  input[type="radio"]:checked ~ label {
    opacity: 1;
    color: $white;
  }
}
